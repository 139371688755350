import { Grid } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { BtnOrange, CustomiseCard } from "../style";
import { BtnWrapper, ButtonWithIcon, DigitalBankWrapper, LeftContent, RightContent, TitleBlock, BtnBlock } from "./style";

const DigitalBank = () => {
  const { t } = useTranslation();

  return (
    <DigitalBankWrapper>
      <CustomiseCard>
        <Grid container>
          <Grid item xs={12} md={12} lg={5} display="flex" justifyContent="center" alignItems="center">
            <>
              <LeftContent>
                <TitleBlock>
                  <p className="time">
                    <Trans i18nKey="bankingService.digitalBank.title1" />
                  </p>
                  <p className="title">
                    <Trans i18nKey="bankingService.digitalBank.title2" />
                  </p>
                </TitleBlock>
                <BtnBlock>
                  <BtnWrapper>
                    <ButtonWithIcon>
                      <img src="/images/ic_baseline-apple.svg" alt="not found" />
                      {t("bankingService.digitalBank.btn2")}
                    </ButtonWithIcon>
                    <ButtonWithIcon>
                      <img src="/images/material-symbols_android.svg" alt="not found" />
                      {t("bankingService.digitalBank.btn3")}
                    </ButtonWithIcon>
                    <ButtonWithIcon>
                      <img src="/images/monitor.svg" alt="not found" />
                      {t("bankingService.digitalBank.btn1")}
                    </ButtonWithIcon>
                    <ButtonWithIcon>
                      {t("bankingService.digitalBank.btn4")}
                    </ButtonWithIcon>
                  </BtnWrapper>
                  <BtnOrange> {t("bankingService.digitalBank.contactUs")}</BtnOrange>
                </BtnBlock>

              </LeftContent>
            </>
          </Grid>
          <Grid item xs={12} md={12} lg={7} display="flex" justifyContent="end" alignItems="center">
            <RightContent>
              <img src="/images/iPhone13.png" className="iphone-img" alt="not found" />
            </RightContent>
          </Grid>
        </Grid>
      </CustomiseCard>
    </DigitalBankWrapper>
  );
};

export default DigitalBank;
