import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ContactUs from "../../Components/ContactUs";
import OpeningAccounts from "../../Components/OpeningAccounts";
import OverseasBusiness from "../../Components/OverseasBusiness";
import { Breadcrumb, SubTitle, Title } from "../../Components/style";
import { MainContainer } from "../style";
import NavTabs from "../../Components/NavTabs";
import { Breadcrumbs, Link } from "@mui/material";

const Banks = () => {
  const { t } = useTranslation();

  return (
    <MainContainer spacing={true}>
      <Container>
        <Breadcrumb>
          <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              <img src="/images/home2.svg" alt="" />
              {t("breadCrumb.main")}
            </Link>
            <Link underline="hover" color="inherit" href="#!">
              {t("breadCrumb.useCase")}
            </Link>
            <Link underline="hover" color="inherit" href="/useCase/banks" className="active">
              {t("breadCrumb.forBank")}
            </Link>
          </Breadcrumbs>
        </Breadcrumb>

        <Title>{t("banks.title")}</Title>
        <SubTitle>{t("banks.subTitle")}</SubTitle>
        <OverseasBusiness />
        <OpeningAccounts />
        <NavTabs defaultActive="forBanks" />
        {/*<ContactUs />*/}
      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
`;

export default Banks;
