import React from "react";
import { CustomiseButton } from "./style";

const Button = ({children , ...rest}) => {
  return (
    <>
      <CustomiseButton variant="contained" {...rest}>{children}</CustomiseButton>
    </>
  );
};

export default Button;
