import styled from "styled-components";

export const Container = styled.div`
  display: block;
  width: 100%;
  max-width: 100vw;
  padding: 3.33vw 5.208333333333333vw;
  background: #fff;
  direction: ${(props) => (props.langDirection === "rtl" ? "rtl" : "ltr")};
`;
export const SocialWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 24px;
  @media (max-width: 450px) {
    gap: 0px;
  }
  @media (max-width: 768px) {
    gap: 10px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #121212;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 14px;
`;

export const FooterContent = styled.div`
  margin-top: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4d4d4d;
`;

export const GridDiv = styled.div`
  display: grid;
  gap: 25px;
  align-items: start;
  grid-template-columns: 3fr 1fr 1fr 1fr;

  @media screen and (max-width: 899px) {
    grid-template-columns: 2fr 1fr;
  }
  @media screen and (max-width: 567px) {
    gap: 5px;
    grid-template-columns: 1fr;
  }
`;
export const GridWrapper = styled.div`
  text-align: left;
  position: relative;
  ul {
    padding: 0;
    list-style: none;
    display: grid;
    gap: 8px;
  }
  .list-item {
    padding: 0 0 15px 0;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #121212;
  }
  .heading {
    padding: 24px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
  li {
    width: 188px;
    height: 17px;
    .link{
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #4d4d4d;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .link{
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    cursor: pointer;
    text-decoration: none;
  }
  .mailLink{
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4d4d4d;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const LogoBlocks = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  width: 220px;
  height: 150px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    height: 100px;
    right: 10px;
  }
`;
export const LogoImg = styled.img`
  width: 120px;
  height: 40px;
  //min-width: fit-content;
  //min-height: fit-content;
`;

export const AppstoreLogo = styled.img`
  width: 120px;
  height: 40px;
`
export const GoogleLogo = styled.img`
  width: 125px;
  height: 45px;
`
