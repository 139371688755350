import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PricingBlocks from "../Components/PricingBlocks";
import { Breadcrumb } from "../Components/style";
import { MainContainer } from "./style";
import { Breadcrumbs, Link } from "@mui/material";

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <MainContainer spacing={true}>
      <Container>
        <Breadcrumb>
          <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              <img src="/images/home2.svg" alt="" />
              {t("breadCrumb.main")}
            </Link>
            <Link underline="hover" color="inherit" href="/pricing" className="active">
              {t("breadCrumb.pricing")}
            </Link>
          </Breadcrumbs>
        </Breadcrumb>
        <PricingBlocks />
      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
`;

export default Pricing;
