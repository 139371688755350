import { Checkbox, FormGroup } from "@mui/material";
import React from "react";
import { CustomFormControlLabel } from "./style";
import { useTranslation } from "react-i18next";

const CustomCheckbox = ({ label, isChecked, onChange }) => {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <CustomFormControlLabel
        control={
          <Checkbox
            onChange={onChange}
            defaultChecked={isChecked}
            color="primary"
          />
        }
        label={t(label)}
      />
    </FormGroup>
  );
};

export default CustomCheckbox;
