import { Breadcrumbs, Link } from "@mui/material";
import Service from "../Components/Service";
import { Breadcrumb } from "../Components/style";
import { MainContainer } from "./style";
import { useTranslation } from "react-i18next";

const ServicePage = () => {
  const { t } = useTranslation();

  return (
    <MainContainer spacing={true}>
      <Breadcrumb>
        <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            <img src="/images/home2.svg" alt="" />
            {t("breadCrumb.main")}
          </Link>
          <Link underline="hover" color="inherit" href="/services" className="active">
            {t("breadCrumb.service")}
          </Link>
        </Breadcrumbs>
      </Breadcrumb>
      <Service />
    </MainContainer>
  );
};

export default ServicePage;
