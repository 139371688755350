import React from "react";
import { PrevNextBtn, TabBtn, Wrapper } from "./style";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const btns = [
  { title: "forBanks", navigate: "/useCase/banks" },
  { title: "forEmi", navigate: "/useCase/emis" },
  { title: "forOffice", navigate: "/useCase/familyOffices" },
];

const NavTabs = ({ defaultActive }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const prevNextHandler = (prevNextBtn) => {
    const activeTab = btns.findIndex((activeTab) => activeTab.title === defaultActive);
    if (prevNextBtn === "prev" && activeTab !== 0) {
      navigate(btns[activeTab - 1].navigate);
    } else if (prevNextBtn === "next" && activeTab !== btns.length - 1) {
      navigate(btns[activeTab + 1].navigate);
    }
  };

  return (
    <Wrapper>
      <PrevNextBtn
        onClick={() => {
          prevNextHandler("prev");
        }}
      >
        <img src="/images/arrow-down.svg" alt="not found" />
      </PrevNextBtn>
      {btns.map((btn) => (
        <TabBtn active={defaultActive === btn.title} onClick={() => navigate(btn.navigate)} key={btn.title}>
          {t(`navTabBtn.${btn.title}`)}
        </TabBtn>
      ))}
      <PrevNextBtn
        onClick={() => {
          prevNextHandler("next");
        }}
      >
        <img src="/images/arrow-right-tabs.svg" alt="not found" />
      </PrevNextBtn>
    </Wrapper>
  );
};

export default NavTabs;
