import {
    ContentWrapper
} from "../../../../Pages/style"

const PlainText = ({attr}) => {
    return (
        <ContentWrapper>
            <p>{attr.Content}</p>
        </ContentWrapper>
    )
}

export default PlainText