import styled from "styled-components";

export const Wrapper = styled.div`
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  padding: 0 5.208333333333333vw;
  margin: 36px 0;

  @media screen and (max-width: 899px) {
    margin: 16px 0;
  }
`;
