import styled from "styled-components";

export const InputDiv = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  position: relative;
  display: flex;
  align-items: center;

  .search {
    position: absolute;
    right: 20px;
  }
`;

export const Input = styled.input`
  padding: 15px;
  width: 100%;
  border: 1px solid gray;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  outline: none;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
`;
