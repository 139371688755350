import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Breadcrumb, Heading, Para, ParaTitle } from "../Components/style";
import { MainContainer } from "./style";
import { Breadcrumbs, Link } from "@mui/material";
import {useParams} from "react-router-dom";
import TermsUs from "./termsAndConditions/us";
import TermsEn from "./termsAndConditions/en";
import TermsEu from "./termsAndConditions/eu";


const TermsAndConditions = () => {
  const { t } = useTranslation();
  const { location } = useParams()
  return (
    <MainContainer spacing={true}>
      <Breadcrumb>
        <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            <img src="/images/home2.svg" alt="" />
            {t("breadCrumb.main")}
          </Link>
          <Link underline="hover" color="inherit" href="#!">
            {t("breadCrumb.resources")}
          </Link>
          {/*<Link underline="hover" color="inherit" href="/termsAndConditions" className="active">*/}
          {/*  {t("breadCrumb.termsAndCondition")}*/}
          {/*</Link>*/}
          <Link underline="hover" color="inherit" href={`/termsAndConditions/${location}`} className="active">
            {t(`breadCrumb.${location}`)} Terms And Conditions
          </Link>
        </Breadcrumbs>
      </Breadcrumb>
      <Container>
        {location == 'us' && <TermsUs />}
        {location == 'eu' && <TermsEu />}
        {location == 'en' && <TermsEn />}
      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
  padding: 0 5.208333333333333vw;
`;

export default TermsAndConditions;
