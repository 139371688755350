import styled from 'styled-components';

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  //min-height: 320px;
  .video-player {
    width: 100%;
    height: 80%;
  }
  @media screen and (max-width: 900px) {
    .video-player {
      border-radius: 16px;
    }
  }
`;