import {MarkdownWrapper} from "./styles"
const Markdown = ({content}) => {
    return (
        <>
        <MarkdownWrapper>
            {
(content??'').split("\n").map((component)=>{
    //Try to decode component
    let result;
    //Decode text inline styles
    if (result = component.match(/^!\[(.+)\]\((.+)\)/)){
        return (
            <>
            <img alt={result[1]} src={result[2]}/>
            </>
        )
    }
    component = component.replaceAll(/\[(.+)\]\((.+)\)/g,'<a href="$2" target="_blank">$1</a>')
    component = component.replaceAll(/\*\*(.+)\*\*/g,'<b>$1</b>')
    component = component.replaceAll(/_(.+)_/g,'<i>$1</i>')
    component = component.replaceAll(/~~(.+)~~/g,'<s>$1</s>')
    component = component.replaceAll(/```/g,'') //@MARK: <Code> tag - feature
    component = component.replaceAll(/^>(.+)/g,'<blockquote>$1</blockquote>') //@MARK: <Quote> tag - feature
    if (result = component.match(/^#\s(.+)/)){
        return (
            <>
            <h1>{result[1]}</h1>
            </>
        )
    }
    if (result = component.match(/^##\s(.+)/)){
        return (
            <>
            <h2>{result[1]}</h2>
            </>
        )
    }
    if (result = component.match(/^###\s(.+)/)){
        return (
            <>
            <h3>{result[1]}</h3>
            </>
        )
    }
    if (result = component.match(/^####\s(.+)/)){
        return (
            <>
            <h4>{result[1]}</h4>
            </>
        )
    }
    return ( <><p dangerouslySetInnerHTML={{__html: component}}/></>)
})
            }
        </MarkdownWrapper>
        </>
    )
}

export default Markdown