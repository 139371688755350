import { Link } from "react-router-dom";
import { Select as MenuSelect, MenuItem as MenuOption } from "@mui/material";
import styled from "styled-components";

export const PrimaryNav = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 76px;
  //margin: 1.667vw;
  padding: 0 3.832291666666667vw !important;
  background: white;//rgba(255, 255, 255, 0.4);
  box-shadow: 0px 8px 14px -3px rgba(95, 95, 95, 0.1);

  //border-radius: 8px;
  position: fixed;
  width: 100%;

  //width: -webkit-fill-available;
  //width: -moz-available;
  backdrop-filter: saturate(200%) blur(20px);
  z-index: 999;

  @media only screen and (max-width: 1215px) {
    padding: 0 25px;
    top: 0;
  }
  .nav-icon {
    width: 30px;
    height: 30px;
  }
`;

export const Logo = styled.img`
  .logo {
    height: 52px;
    width: 157.31px;
  }

  @media only screen and (max-width: 980px) {
    width: 96px;
  }
`;

export const MenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

export const LangSelectorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 16px;
`

export const ActionsContainer = styled.div`
  display: flex; 
  .headerlink{
      align-items: center;
    display: flex;
    text-decoration: none;}
`

export const MenuItem = styled(Link)`
  padding: 0px;
  gap: 4px;
  text-decoration: none;
  color: ${(props) => (props.primary ? "#ff931e" : "#4d4d4d")} !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  &:hover {
    color: orange !important;
  }
  .menu-img {
    margin-left: 10px;
  }
`;

export const LoginBtn = styled.button`
  background: #ff931e;
  border-radius: 12px;
  padding: 14.5px 24px;
  border: none;
  cursor: pointer;
  color: #ffffff;

  .mailLink{
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const NavIcon = styled.img`
  src: url("/public/images/nav-icon.svg");
`;

export const Select = styled.select`
  text-align: left;
  border: none;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border: none;
  outline: none;
  .flag-img,
  img {
    width: 15px;
  }
  option {
    display: block;
    text-align: left;
    direction: ltr;
    position: relative;
    ::before {
      content: "";
      position: absolute;
      background-color: red;
      width: 25px;
      height: 25px;
      top: 0;
      left: 0;
    }
  }
`;

export const FlagWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    padding: 0;
  }
`;

export const FlagIcon = styled.img`
  width: 25px;
  height: 25px;
`;

export const CustomSelect = styled(MenuSelect)`
  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  & > .MuiSvgIcon-root {
    right: ${(props) =>
            props.textDirection === "rtl" ? "unset" : "7px"} !important;
    left: ${(props) =>
            props.textDirection === "rtl" ? "7px" : "unset"} !important;
  }

  & > .MuiInputBase-input {
    padding-left: ${(props) =>
            props.textDirection === "rtl" ? "32px" : "0px"} !important;
    padding-right: ${(props) =>
            props.textDirection === "rtl" ? "0px" : "32px"} !important;
  }

  fieldset {
    border: none;
  }
`;

export const CustomMenuItem = styled(MenuOption)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  text-align: left;
`;
