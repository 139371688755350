import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0px 5.20vw;
`

export const ServiceTitle = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 53px;
    letter-spacing: -0.2px;
    color: #121212;
    text-align: center;
    padding: 76px 0 36px 0;
    span{
        color: #FF931E;
    }
`

export const ServiceHeroSection = styled.div`
    min-height: 57.66vw;
    max-width: 100%;
    font-style: normal;
    background: url(/images/B2B.svg) center center no-repeat;
    background-size:contain;
`

export const FeatureHeading = styled.div`
    padding:100px 0px 10px;
    h1{
        font-style: normal;
        font-weight: 700;
        font-size: 44px;
        line-height: 53px;
        text-align: center;
        letter-spacing: -0.2px;
        color: #121212;
    }
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #4D4D4D;
    }
`

export const CardWrapper = styled.div`
    padding-bottom:214px;
`

export const MainBox = styled.div`
  .flip-box {
    background-color: transparent;
    width: 292px;
    height: 470px;
    perspective: 50rem;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-box-front {
    color: black;
    padding: 0px;

    .front-card{
      height: 100%;
      border-radius: 24px;
      padding: 0px;
      .heading{
        padding: 32px  24px 0px;
        margin:0;
      }
    }
  }

  .flip-box-back {
    color: white;
    transform: rotateY(180deg);
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    .back-card{
      background: rgba(162, 162, 163, 0.1);
      border-radius: 16px;
      backdrop-filter: blur(7px);
      height: 470px;
      padding:32px 23px;
    }
  }

  .heading{
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #121212;
  }

  .description{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #686C72;
  }


  @media (max-width: 375px) {
    .flip-box{
      width: 100vw;
      padding-left: -16px;
    }
  }
`;
