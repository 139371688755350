import { Trans, useTranslation } from "react-i18next";
import {HeroSection, HeroSectionImg, Wrapper, Wrap, AbsoluteWrap} from "./style";
import BoxFrame from "../BoxFrame";

const AboutUsHeroSection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BoxFrame alignImage="right" dataImage="/images/HelpfulGuy.svg" width={'95%'}>
        <HeroSection>
          <div>
            <p className="title">
              <Trans i18nKey="aboutUs.title" />
            </p>
            <p className="description">{t("aboutUs.desc")}</p>
          </div>
        </HeroSection>
      </BoxFrame>
      <Wrap>
        <img src="/images/Sikorsky1.svg" alt="Sikorsky photo"/>
        <img src="/images/Sikorsky2.svg" alt="Helicopter photo"/>
        <HeroSection>
          <div>
            <p className="title" style={{ paddingLeft: '16px' }}>
              <Trans i18nKey="aboutUs.title2" />
            </p>
            <p className="description">{t("aboutUs.desc2")}</p>
          </div>
        </HeroSection>
      </Wrap>
      <HeroSectionImg>
        <div className="text__container">
          <AbsoluteWrap>
            <div className="title">{t("aboutUs.motoTitle")}</div>
            <p className={'moto'}>
              <Trans i18nKey="aboutUs.moto" />
            </p>
          </AbsoluteWrap>
        </div>
      </HeroSectionImg>
      <HeroSection>
          <div>
            <p className="title">
              <Trans i18nKey="aboutUs.title3" />
            </p>
            <div className="block">
              <BoxFrame alignImage="right" dataImage="/images/AboutUs1.svg">
                <p className={'description description--right'}>
                  <Trans i18nKey="aboutUs.block1" />
                </p>
              </BoxFrame>
            </div>
            <div className="block">
              <BoxFrame alignImage="left" dataImage="/images/AboutUs2.svg">
                <p className={'description'}>
                  <Trans i18nKey="aboutUs.block2" />
                </p>
                <p className={'description'}>
                  <Trans i18nKey="aboutUs.block2a" />
                </p>
              </BoxFrame>
            </div>
            <div className="block">
              <BoxFrame alignImage="right" dataImage="/images/AboutUs3.svg">
                <p className={'description description description--right'}>
                  <Trans i18nKey="aboutUs.block3" />
                </p>
              </BoxFrame>
            </div>
          </div>
        </HeroSection>
    </Wrapper>
  );
};

export default AboutUsHeroSection;
