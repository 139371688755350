import styled from "styled-components";

export const MainContainer = styled.div`
  background: ${(props) => props.isWhiteBg ?"#ffffff": "#f1f3f5"};
  padding-top: ${(props) => props.spacing && "115px"};
`;

export const ContentWrapper = styled.div`
  padding: 0px 5.20833vw;
  width: 100%;
  max-width: 1340px;
  margin: 0px auto;
`