import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ContactUs from "../../Components/ContactUs";
import OurHelp from "../../Components/OurHelp";
import { Breadcrumb, SubTitle, Title } from "../../Components/style";
import WealthManagement from "../../Components/WealthManagement";
import { MainContainer } from "../style";
import NavTabs from "../../Components/NavTabs";
import { Breadcrumbs, Link } from "@mui/material";

const FamilyOffices = () => {
  const { t } = useTranslation();

  return (
    <MainContainer spacing={true}>
      <Container>
        <Breadcrumb>
          <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              <img src="/images/home2.svg" alt="" />
              {t("breadCrumb.main")}
            </Link>
            <Link underline="hover" color="inherit" href="#!">
              {t("breadCrumb.useCase")}
            </Link>
            <Link underline="hover" color="inherit" href="/useCase/familyOffices" className="active">
              {t("breadCrumb.forOffice")}
            </Link>
          </Breadcrumbs>
        </Breadcrumb>
        <Title>{t("familyOffices.title")}</Title>
        <SubTitle>{t("familyOffices.subTitle")}</SubTitle>
        <WealthManagement />
        <OurHelp />
        <NavTabs defaultActive="forOffice" />
        {/*<ContactUs />*/}
      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
`;

export default FamilyOffices;
