import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import BoxFrame from "../BoxFrame";
import FormGroup from "../FormGroup";
import Textarea from "../Textarea";
import Button from "../Button";
import { CustomiseCard, FrameTitle } from "../style";
import { Wrapper } from "./style";

const ContactUs = ({ dataImage }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Wrapper>
        <CustomiseCard padding="0 0 0 2.5vw">
          <BoxFrame alignImage="right" dataImage={dataImage ?? "/images/2_Team V1.svg"}>
            <FrameTitle>{t("contactUs.title")}</FrameTitle>
            <Grid container columns={{ xs: 1, sm: 6, md: 12 }} spacing={2}>
              <Grid item xs={1} sm={6} md={6}>
                <FormGroup placeholder="First Name" />
              </Grid>
              <Grid item xs={1} sm={6} md={6}>
                <FormGroup placeholder="Last Name" />
              </Grid>
              <Grid item xs={1} sm={12} md={12}>
                <FormGroup placeholder="Email" />
              </Grid>
              <Grid item xs={1} sm={12} md={12}>
                <Textarea minRows="5" placeholder="How can we help?" />
              </Grid>
            </Grid>
            <Button sx={{ my: "16px" }}>Send</Button>
          </BoxFrame>
        </CustomiseCard>
      </Wrapper>
    </div>
  );
};

export default ContactUs;
