import { css } from "@emotion/react";
import styled from "styled-components";

export const GridWrapper = styled.div`
    padding: 24px 0px;
`
export const GridCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: transparent;
    padding: 36px;
    position: relative;
    z-index: 0;
    height: 264px;
    &:before{
        content:"";
        border-radius: 24px;
        background: rgba(241, 243, 245, 1);
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) top,
        0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) right,
        0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) bottom,
        0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) left,
        0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) box-shadow,
        0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) background-color;
    }
    span{
        z-index: 2;
        font-family: SF Pro Display;
        font-size: 24px;
        font-weight: 500;
        line-height: 28.64px;
        letter-spacing: -0.20000000298023224px;
        text-align: left;
        color: rgba(56, 66, 84, 1);
    }
    &:hover{
        &:before{
            
        }
        & > div {
            
        }
    }
`
const backgroundPosition = (position)=>{
    switch(position?.toLowerCase()){
        case "top left":
            return "top left";
        case "top center":
            return "top center";
        case "top right":
            return "top right";
        case "center left":
            return "center left";
        case "center center":
            return "center center";
        case "center right":
            return "center right";
        case "bottom left":
            return "bottom left";
        case "bottom center":
            return "bottom center";
        case "bottom right":
            return "bottom right";
    }
    return "center center";
}
export const GridCardInnerWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 24px;
    z-index: 1;
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) top,
        0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) right,
        0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) bottom,
        0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) left,
        0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) box-shadow,
        0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) background-color;
    background-image: url(${(props)=>props?.image ?? ''});
    background-repeat: no-repeat;
    background-position: ${(props)=>backgroundPosition(props?.position ?? 'Center Center')}
`