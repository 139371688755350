import { Trans, useTranslation } from "react-i18next";
import {HeroSection, Wrapper, Wrap } from "./style";
import BoxFrame from "../BoxFrame";
import BoxVideoFrame from "../BoxVideoFrame";

const AboutUsHeroDark = () => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 900;
  return (
    <Wrapper>
      <p className="title">
        <Trans i18nKey="aboutUs.dark.title" />
      </p>
      <Wrap>
        <BoxFrame alignImage="right" dataImage="/images/Patent.svg">
          <HeroSection>
            <div>
              <p className="title">
                <Trans i18nKey="aboutUs.dark.title1" />
              </p>
              <p className="description">{t("aboutUs.dark.desc1")}</p>
            </div>
          </HeroSection>
        </BoxFrame>
      </Wrap>
      <Wrap>
        <BoxVideoFrame alignImage={isMobile ? "right" : "left"} videoId="UH18BPcfSzM?si=8U-9pvVEvXb3wjNI">
          <HeroSection>
            <div>
              <p className="title">
                <Trans i18nKey="aboutUs.dark.title2" />
              </p>
              <p className="description">{t("aboutUs.dark.desc2")}</p>
              <p className="subTitle">
                {t("aboutUs.dark.title2a")}
              </p>
              <p className="description">{t("aboutUs.dark.desc2a")}</p>
            </div>
          </HeroSection>
        </BoxVideoFrame>
      </Wrap>
      <Wrap>
        <BoxVideoFrame alignImage={isMobile ? "right" : "left"} videoId="Fvt3m43CZ4Y?feature=shared">
          <HeroSection>
            <div>
              <p className="subTitle">
                {t("aboutUs.dark.title3")}
              </p>
              <p className="description">{t("aboutUs.dark.desc3")}</p>
            </div>
          </HeroSection>
        </BoxVideoFrame>
      </Wrap>
      <BoxFrame alignImage="right" dataImage="/images/Patent2.svg">
        <HeroSection>
          <div>
            <p className="title">
              <Trans i18nKey="aboutUs.dark.title4" />
            </p>
            <p className="description">{t("aboutUs.dark.desc4")}</p>
            <p className="description">{t("aboutUs.dark.desc4a")}</p>
          </div>
        </HeroSection>
      </BoxFrame>
      <Wrap>
        <BoxVideoFrame  alignImage={isMobile ? "right" : "left"} videoId="Le7UciUnouw?si=yJCsV5a-z1BDSrMR">
          <HeroSection>
            <div>
              <p className="title">
                {t("aboutUs.dark.title5")}
              </p>
              <p className="description">{t("aboutUs.dark.desc5")}</p>
            </div>
          </HeroSection>
        </BoxVideoFrame>
      </Wrap>
      <BoxFrame alignImage="right" dataImage="/images/Growth.svg">
        <HeroSection>
          <div>
            <p className="title">
              <Trans i18nKey="aboutUs.dark.title6" />
            </p>
            <p className="description">{t("aboutUs.dark.desc6")}</p>
          </div>
        </HeroSection>
      </BoxFrame>
      <Wrap></Wrap>
      <BoxFrame  alignImage={isMobile ? "right" : "left"} dataImage="/images/world1.svg">
        <HeroSection>
          <div>
            <p className="title">
              <Trans i18nKey="aboutUs.dark.title7" />
            </p>
            <p className="description">{t("aboutUs.dark.desc7")}</p>
          </div>
        </HeroSection>
      </BoxFrame>
      <Wrap></Wrap>
    </Wrapper>
  );
};

export default AboutUsHeroDark;
