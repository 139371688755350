import { OutlinedInput } from "@mui/material";
import React from "react";
import { CustomiseFormControl } from "./style";

const FormGroup = ({ placeholder }) => {
  return (
    <>
      <CustomiseFormControl>
        <OutlinedInput placeholder={placeholder} />
      </CustomiseFormControl>
    </>
  );
};

export default FormGroup;
