import {
    GridCardWrapper,
    GridCardInnerWrapper
} from "./styles"
import { Grid } from "@mui/material";

const GridCard = ({attr}) => {
    let xs = 12;
    let md = 6;
    let lg = 4;
    switch(attr?.Size?.toLowerCase()){
        case "large":
            md = 12;
            lg = 12;
        break;
        case "medium":
            md = 6;
            lg = 6;
            break;
        case "small":
            lg = 3;
            md = 3;
            break;
    }
    return (
        <Grid item xs={xs} md={md} lg={lg}>
        <GridCardWrapper>
            <span>{attr.Title}</span>
            { attr.Image ? 
            <GridCardInnerWrapper position={attr.ImagePosition ?? 'Center Center'} image={`${process.env.REACT_APP_CMSURL}${attr.Image.url}`}/>
            : [] }
        </GridCardWrapper>
        </Grid>
    )
}

export default GridCard