import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import { MainBox } from "./style";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";

const EmiCard = () => {
  const { t } = useTranslation();
  return (
    <>
      <MainBox>
        <div className="flip-box">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <Card className="front-card">
                <CardContent sx={{ padding: "0" }}>
                  <Typography gutterBottom variant="h4" component="div" mt={1} className="heading">
                    {t("mainPage.bankingService.emi.title")}
                  </Typography>
                </CardContent>
                <CardMedia
                  sx={{
                    height: 511,
                    backgroundSize: "cover",
                    borderRadius: "0px 0px 10px 10px",
                    margin: 0,
                  }}
                  image={"/images/electric money.svg"}
                />
              </Card>
            </div>
            <div className="flip-box-back">
              <Card className="back-card">
                <CardContent sx={{ padding: "0", height: '80%' }}>
                  <Typography variant="h4" component="div" mb={1} mt={1} className="heading">
                    {t("mainPage.bankingService.emi.title")}
                  </Typography>
                  <div className="back-card__body">
                    <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                      <img src="/images/Cryto Currency.svg" alt="not found" width={32} height={32} />
                      <Typography variant={"body2"}>{t("mainPage.bankingService.emi.item1")}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                      <img src="/images/archive-book.svg" alt="not found" width={32} height={32} />
                      <Typography variant={"body2"}>{t("mainPage.bankingService.emi.item2")}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                      <img src="/images/DDLiner.svg" alt="not found" width={32} height={32} />
                      <Typography variant={"body2"} gutterBottom>
                        {t("mainPage.bankingService.emi.item3")}
                      </Typography>
                    </Stack>
                  </div>
                </CardContent>
                {/*<CardActions className="learn-more">*/}
                {/*  <Button variant="text" color={"warning"} sx={{ padding: 0 }}>*/}
                {/*    <Link to={t("breadCrumb.product")} className={"mailLink"}>{t("mainPage.bankingService.learnMore")}</Link>*/}
                {/*  </Button>*/}
                {/*</CardActions>*/}
              </Card>
            </div>
          </div>
        </div>
      </MainBox>
    </>
  );
};

export default EmiCard;
