import React from "react";
import BoxFrame from "../BoxFrame";
import { FrameTitle, FrameContent, CustomiseCard } from "../style";
import { Wrapper } from "./style";
import { useTranslation } from "react-i18next";

const OurHelp = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BoxFrame alignImage="left" dataImage="/images/Frame48095693.svg">
        <FrameTitle>{t("ourHelp.title")}</FrameTitle>
        <FrameContent>
          <CustomiseCard padding="24px" margin="24px 0">
            <p>{t("ourHelp.card.desc1")}</p>
            <p>{t("ourHelp.card.desc2")}</p>
          </CustomiseCard>
          <CustomiseCard padding="24px" margin="24px 0">
            <p>{t("ourHelp.card.desc3")}</p>
          </CustomiseCard>
        </FrameContent>
      </BoxFrame>
    </Wrapper>
  );
};

export default OurHelp;
