import {
    ContentWrapper
} from "../../../../Pages/style"
import {
    BlockStepWrapper,
    BlockStepNumber,
    BlockStepDescription
} from "./styles"

const BlockStep = ({attr}) => {    
    return (
        <BlockStepWrapper>
            <BlockStepNumber>Step {attr.Step}</BlockStepNumber>
            {attr.Description ? <BlockStepDescription>{attr.Description}</BlockStepDescription> : []}
        </BlockStepWrapper>
    )
}

export default BlockStep