import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Breadcrumb, Heading, Para, ParaTitle } from "../../Components/style";
import { MainContainer } from "../style";
import { Breadcrumbs, Link } from "@mui/material";
import {useParams} from "react-router-dom";

const TermsEu = () => {
  const { t } = useTranslation();
  const { location } = useParams();
  return (
    <MainContainer spacing={true}>
      <Container>
        <Heading margin="0 0 36px 0">{t("termsAndConditions.heading")} EU</Heading>
        <ParaTitle margin="0 0 16px 0">{t("termsAndConditions.title")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.body.desc1")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.body.desc2")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.body.points.a")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.body.points.b")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.body.points.c")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.body.points.d")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que1")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans1Points.1")}</Para>
        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que2")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans2Points.1")}</Para>
        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que3")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans3Points.1")}</Para>
        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que4")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans4Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans4Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans4Points.3")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que7")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans7Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que8")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans8Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans8Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans8Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans8Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans8Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans8Points.6")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans8Points.7")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans8Points.8")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans8Points.9")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans8Points.10")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans8Points.11")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que9")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans9Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que10")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans10Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans10Points.2")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que11")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans11Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans11Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans11Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans11Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans11Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans11Points.6")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans11Points.7")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans11Points.8")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que12")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans12Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que13")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans13Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans13Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans13Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans13Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans13Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans13Points.6")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans13Points.7")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans13Points.8")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans13Points.9")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans13Points.10")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans13Points.11")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que14")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans14Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans14Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans14Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans14Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans14Points.5")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que15")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans15Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que16")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans16Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que17")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans17Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que18")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans18Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que19")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans19Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que20")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans20Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que21")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans21Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans21Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans21Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans21Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans21Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans21Points.6")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans21Points.7")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans21Points.8")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans21Points.9")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans21Points.10")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que22")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans22Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans22Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans22Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans22Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans22Points.5")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que23")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans23Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans23Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans23Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans23Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans23Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans23Points.6")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans23Points.7")}</Para>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans23Points.8")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que24")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans24Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que25")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans25Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("termsAndConditions.questions.que26")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("termsAndConditions.questions.ans26Points.1")}</Para>

        <Para margin="0 0 16px 0">&nbsp;</Para>
      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
  padding: 0 5.208333333333333vw;
`;

export default TermsEu;
