import { Grid } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import ClientData from "../Components/ClientData";
import KnowYourCustomer from "../Components/KnowYourCustomer";
import Matching from "../Components/Matching";
import MonitoringSystem from "../Components/MonitoringSystem";
import { CustomiseCard, Para } from "../Components/style";
import ProductHeroSection from "../Components/ProductHeroSection";
import { MainContainer } from "./style";
import {Link} from "react-router-dom";

const Product = () => {
  const { t } = useTranslation();
  return (
    <MainContainer>
      <Container>
        <ProductHeroSection />
        <Grid container columns={{ xs: 1, sm: 6, md: 12 }}>
          <Grid item xs={1} sm={6} md={6}>
            <Para padding="0 5.208333333333333vw" margin="16px 0">
              <Trans>{t("product.info")}<Link to={"mailto: sales@digidoe.com"} className={"mailLink"}><span>sales@digidoe.com</span></Link></Trans>
            </Para>
          </Grid>
        </Grid>
        <CustomiseCard margin="0 5.208333333333333vw 24px">
          <KnowYourCustomer />
        </CustomiseCard>
        <CustomiseCard margin="0 5.208333333333333vw 24px">
          <ClientData />
        </CustomiseCard>
        <CustomiseCard margin="0 5.208333333333333vw 24px">
          <Matching />
        </CustomiseCard>
        <CustomiseCard margin="0 5.208333333333333vw 24px">
          <MonitoringSystem />
        </CustomiseCard>
      </Container>
      <Spacer/>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
`;

const Spacer = styled.div`
  display: block;
  width: 100%;
  height: 20px;
  background: #f1f3f5;
`;

export default Product;
