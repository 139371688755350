import { FormControlLabel } from "@mui/material";
import styled from "styled-components";

export const CustomFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-family: "SF Pro Text" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #292929 !important;
  }
`;
