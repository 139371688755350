import {
    ContentWrapper
} from "../../../../Pages/style"
import {
    StepsWrapper,
    StepsContainerWrapper
} from "./styles"
import Step from "./Step"
import {makeId} from "../../../../helpers/functions"

const StepsContainer = ({attr}) => {
    const id = makeId()
    const handler = (event)=>{
        const element = document.getElementById(`steps${id}`)
        if (element){
            const y = element.getBoundingClientRect().top
            const nav = document.getElementsByTagName("nav")
            if (nav && nav.length > 0){
                const h = nav[0].getBoundingClientRect().height
                if (y <= h){
                    if (!element.classList.contains("stacked")){
                        const elementHeight = element.getBoundingClientRect().height
                        element.style.height = `${elementHeight}px`
                        element.style.top = `${h}px`
                        element.classList.add("stacked")
                    }
                }else{
                    element.classList.remove("stacked")
                }
            }
        }
    }
    window.addEventListener("scroll",handler)
    return (
        <StepsContainerWrapper id={`steps${id}`}>
            <ContentWrapper>
                <StepsWrapper>
                    {attr.Steps.map((step)=>{
                        return <Step attr={step}/>
                    })}
                </StepsWrapper>
            </ContentWrapper>
        </StepsContainerWrapper>
    )
}

export default StepsContainer