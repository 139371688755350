import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { MainContainer } from "./style";
import { Breadcrumbs } from "@mui/material";
import {Link} from "react-router-dom";
import { Breadcrumb, SectionTitle } from "../Components/style";
import StrapiBuilder from "../Components/Strapi/Page/Builder"
import ContactUs from "../Components/ContactUsForm/ContactUs";

const Partnership = () => {
  const { t } = useTranslation();
  const [pageContent, setPageContent] = React.useState({})
  const [contactUsFormVisible, setContactUsFormVisible] = React.useState(false)
  
  React.useEffect(()=>{
    const populate = [
      'Hero.Actions',
      'Hero.Image',
      'Content.Actions',
      'Content.Image',
      'Content.Steps',
      'Content.Steps.Icon',
      'Content.Steps.DescriptionIcon',
      'Content.Accordion',
      'Content.Accordion.Icon',
      'Content.Cards',
      'Content.Cards.Image',
      'Content.Buttons',
      'Content.Buttons.Icon',
      'Content.Buttons.attachment'
    ]
    fetch(`${process.env.REACT_APP_CMSURL}/api/partnership?populate=${populate.join(',')}`)
      .then((response) => response.json())
      .then((response) => {
        setPageContent(response?.data ?? {})
      })
  },[])

   

  const handleAction = (event)=>{
    const action = event.Action.toLowerCase().replaceAll(" ","")
    switch(action){
      case "contactus":
        setContactUsFormVisible(true);
        break;
    }
  }

  return (
    <MainContainer spacing={true} isWhiteBg={true}>
      <Container>
        <Breadcrumb>
          <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              <img src="/images/home2.svg" alt="" />
              {t("breadCrumb.main")}
            </Link>
            <Link underline="hover" color="inherit" href="/partnership" className="active">
              {t("breadCrumb.partnership")}
            </Link>
          </Breadcrumbs>
        </Breadcrumb>
        <StrapiBuilder content={pageContent?.Hero} emit={handleAction}/>
        <StrapiBuilder content={pageContent?.Content} emit={handleAction}/>
        {contactUsFormVisible ? <ContactUs close={()=>{setContactUsFormVisible(false)}} submit={()=>{setContactUsFormVisible(false)}}/> : []}
      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
  background-color: #fff;
`;

const Spacer = styled.div`
  display: block;
  width: 100%;
  height: 20px;
  background: #f1f3f5;
`;

export default Partnership;
