import { useTranslation } from "react-i18next";
import AboutUsHeroSection from "../Components/AboutUsHeroSection";
import AboutUsHeroDark from "../Components/AboutUsHeroDark";

import ContactUs from "../Components/ContactUs";
import SimpleTimeline from "../Components/SimpleTimeLine";
import { FrameTitle, Breadcrumb } from "../Components/style";
import { MainContainer } from "./style";
import { Breadcrumbs, Link } from "@mui/material";

const AboutUS = () => {
  const { t } = useTranslation();

  return (
    <MainContainer spacing={true}>
      <Breadcrumb>
        <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            <img src="/images/home2.svg" alt="" />
            {t("breadCrumb.main")}
          </Link>
          <Link underline="hover" color="inherit" href="/aboutUs" className="active">
            {t("breadCrumb.aboutUs")}
          </Link>
        </Breadcrumbs>
      </Breadcrumb>
      <AboutUsHeroSection />
      <AboutUsHeroDark>

      </AboutUsHeroDark>
      {/*<FrameTitle textAlign="center">{t("aboutUs.milestone")}</FrameTitle>*/}
      {/*<SimpleTimeline />*/}
      {/*<ContactUs />*/}
    </MainContainer>
  );
};

export default AboutUS;
