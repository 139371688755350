import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import YoutubeVideo from "../YoutubeVideo";

const BoxFrame = (props) => {
  const { i18n } = useTranslation();

  return (
    <Grid container columns={{ xs: 1, sm: 6, md: 12 }} >
      {props.alignImage === "left" ? (
        <>
          <Grid xs={1} sm={6} md={6} style={{ maxWidth: '540px', width: '100%', height: '400px', maxHeight: '50vw' }} >
            <YoutubeVideo videoId={props.videoId} />
          </Grid>
          <Grid xs={1} sm={6} md={6} sx={{ m: 'auto', direction: i18n.dir() }}>
            <Item>{props.children}</Item>
          </Grid>
        </>
      ) : (
        <>
          <Grid xs={1} sm={6} md={6} sx={{ m: 'auto', direction: i18n.dir() }}>
            <Item>{props.children}</Item>
          </Grid>
          <Grid xs={1} sm={6} md={6} style={{ maxWidth: '540px', width: '100%', height: '400px', maxHeight: '50vw' }} display="flex" justifyContent="center" alignItems="center">
            <Video>
              <YoutubeVideo videoId={props.videoId} />
            </Video>
            {props.caption ?? ""}
          </Grid>
        </>
      )}
    </Grid>
  );
};

const Item = styled.div`
  padding: 0px 20px;
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .img-last{
    display: flex;
    align-items: end;
    justify-content: end;
  }
`;

const Video = styled.div`
  @media screen and (max-width: 900px) {
    width: 100%;
    height: 100%;
  }
`

export default BoxFrame;
