import React from "react";
import { useTranslation } from "react-i18next";
import BoxFrame from "../BoxFrame";
import { FrameContent, FrameContentTitle, FrameTitle } from "../style";
import { Wrapper } from "./style";

const ClientData = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BoxFrame alignImage="left" dataImage="/images/Frame48095716.svg">
        <FrameTitle>{t("clientData.title")}</FrameTitle>
        <FrameContent>
          <FrameContentTitle>
            <p>{t("clientData.desc1Title")}</p>
          </FrameContentTitle>
          <p>{t("clientData.desc1")}</p>
          <FrameContentTitle>
            <p>{t("clientData.desc2Title")}</p>
          </FrameContentTitle>
          <p>{t("clientData.desc2")}</p>
        </FrameContent>
      </BoxFrame>
    </Wrapper>
  );
};

export default ClientData;
