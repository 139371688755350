import styled from "styled-components";

export const Wrapper = styled.div`
  background: #ffffff;
  padding: 115px 5.208333333333333vw 0 5.208333333333333vw;
  margin-bottom: 51px;
`;

export const CareerCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row: auto auto;
  grid-gap: 24px;

  @media screen and (max-width: 899px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;

  p {
    margin: 0 0 2px 0;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #121212;
  }

  span {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #686c72;
  }
`;
