import { Pagination, Select } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 5.208333333333333vw;
  padding-bottom: 100px;
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (max-width: 899px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

export const CustomDropDown = styled(Select)`
  background: #ffffff !important;
  border-radius: 12px !important;
  font-family: "SF Pro Text" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #333333 !important;

  svg {
    top: calc(53% - 0.5em);
  }

  fieldset {
    border-width: 1px !important;
    border-color: #f1f1f1 !important;
  }
`;

export const DropDownLabel = styled.span`
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #121212;
  margin-right: 8px;
`;

export const CustomPagination = styled(Pagination)`
  button {
    background: #ffffff !important;
    border: 1px solid #f1f1f1 !important;
    border-radius: 12px !important;
    font-family: "SF Pro Text" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #333333 !important;
    padding: 12px;
  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background: #ff931e !important;
    color: #ffffff !important;
  }
`;

export const JobsListFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 899px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

export const PageSelectWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
