import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 6.875vw 5.208333333333333vw;

`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 53px;
  letter-spacing: -0.2px;
  color: #121212;
  span{
    color: #FF931E;
  }

`

export const Content = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: #4D4D4D;
  padding-bottom: 28px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  span{
    font-weight:700
  }
`
const Link = ({ className, children }) => (
  <a className={className}>
    {children}
  </a>
);

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: 0 auto;
`
export const StyledLink = styled(Link)`
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: rgb(77, 77, 77);
  max-width: 100vw;
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
`;
