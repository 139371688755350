import styled from "styled-components";

export const MainBox = styled.div`
  .flip-box {
    background-color: transparent;
    width: 396px;
    height: 415px;
    //border: 1px solid #f1f1f1;
    perspective: 50rem;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-box-front {
    color: black;
    padding: 0px;

    .front-card{
      height: 100%;
      border-radius: 24px;
      padding: 0px;
      .heading{
        padding: 36px  28px 0px;
      }
    }
  }

  .flip-box-back {
    color: white;
    transform: rotateY(180deg);
    box-shadow: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    .back-card{
      background: #F7E7D3;
      backdrop-filter: blur(7px);
      height: 415px;
      padding:36px 28px;
      border-radius: 24px;
      &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 90%;
        p {
          font-size: 20px;
        }
      }
      
    }
  }

  .heading{
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #121212;
  }

  .learn-more{
    position: absolute;
    bottom:25px
  }
  
  .mailLink{
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ff931e;
    cursor: pointer;
    text-decoration: none;
  }

  @media (max-width: 396px) {
    .flip-box{
      width: 100vw;
      padding:2vw;
    }
  }
`;
