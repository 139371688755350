import { Button } from "@mui/material";
import styled from "styled-components";

export const CustomiseButton = styled(Button)`
  background-color: rgba(255, 147, 30, 0.08) !important;
  border-radius: 12px !important;
  font-family: "SF Pro Text" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center !important;
  color: #ff931e !important;
  box-shadow: none !important;
  padding: 12px 24px !important;
`;
