import { Link } from "react-router-dom";
import styled from "styled-components";
export const DropdownMenu = styled.div``;
export const DropdownItem = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  .title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    margin: 0;
  }
  .name-secondary {
    margin: 0;
    font-size: 12px;
  }
  .image-secondary {
    width: 24px;
    height: 24px;
  }
  img {
    height: 35px;
    width: 35px;
  }
  &:hover {
    background: whitesmoke;
  }
`;
export const MenuItemBox = styled.div`
  padding: 0px !important;
  font-family: "SF Pro Text" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: ${(props) =>
    props.primary !== null ? "#ff931e" : "#4d4d4d"} !important;
`;

export const MenuItem = styled(Link)`
  padding: 0px;
  /* padding-right: 28px; */
  gap: 4px;
  text-decoration: none;
  color: #4d4d4d;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  img {
    padding: 0 25px 0 0;
  }
  &:hover {
    color: #ff931e;
  }
`;
