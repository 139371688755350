import React from "react";
import BoxFrame from "../BoxFrame";
import { FrameTitle, FrameContent, CustomiseCard } from "../style";
import { Wrapper } from "./style";
import { Trans, useTranslation } from "react-i18next";

const EmiChallenges = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BoxFrame alignImage="right" dataImage="/images/Frame48095673.svg">
        <FrameTitle>
          <Trans>{t("emiChallenges.title")}</Trans>
        </FrameTitle>
        <FrameContent>
          <CustomiseCard padding="24px" margin="24px 0">
            <img src="/images/1.svg" alt="not-found" />
            <p>{t("emiChallenges.card.desc1")}</p>
          </CustomiseCard>
          <CustomiseCard padding="24px" margin="24px 0">
            <img src="/images/2.svg" alt="not-found" />
            <p>{t("emiChallenges.card.desc2")}</p>
          </CustomiseCard>
          <CustomiseCard padding="24px" margin="24px 0">
            <img src="/images/3.svg" alt="not-found" />
            <p>{t("emiChallenges.card.desc3")}</p>
          </CustomiseCard>
          <p>{t("emiChallenges.desc1")}</p>
        </FrameContent>
      </BoxFrame>
    </Wrapper>
  );
};

export default EmiChallenges;
