import React from "react";
import {
  CustomDropDown,
  CustomPagination,
  DropDownLabel,
  JobsListFilterWrapper,
  PageSelectWrap,
  Wrap,
  Wrapper,
} from "./style";
import { Heading } from "../style";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Pagination, PaginationItem } from "@mui/material";
import JobsList from "../JobsList";
import jobs from "../../Constants/jobs";
import JobsFilters from "../JobsFilters";
import { SelectIcon } from "../../Icons/SelectIcon";
import { FirstPageIcon } from "../../Icons/FirstPageIcon";
import { LastPageIcon } from "../../Icons/LastPageIcon";
const qs = require("qs");

const JobOpportunity = () => {
  const [OpportunitiesItems, setOpportunitiesItems] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(1);
  React.useEffect(() => {
    pageChange(null, 1);
  }, []);
  const applyFilters = (filters) => {
    setForm(filters);
    pageChange(null, 1, sortBy, filters);
  };
  const pageChange = (event, page, sort, formFilters, searchField) => {
    let sortField = "Date";
    let sortOrder = "desc";
    if ((sort ?? sortBy) == 1) {
      sortField = "Date";
      sortOrder = "desc";
    } else if ((sort ?? sortBy) == 2) {
      sortField = "Date";
      sortOrder = "asc";
    }
    let args = {
      filters: {},
      sort: [`${sortField}:${sortOrder}`],
      populate: "*",
      pagination: {
        page: page,
        pageSize: 9,
      },
    };
    const searchString = searchField ?? search;
    if (searchString) {
      args.filters.Title = {
        $containsi: searchString,
      };
    }
    const filters = formFilters ?? form;
    if (filters.locations) {
      args.filters.Location = {
        documentId: {
          $in: filters.locations,
        },
      };
    }
    if (filters.jobStyles) {
      args.filters.Style = {
        documentId: {
          $in: filters.jobStyles,
        },
      };
    }
    if (filters.date && filters.date != "0") {
      const date = new Date();
      if (filters.date == "1") {
        date.setHours(date.getHours() - 24);
      }
      if (filters.date == "2") {
        date.setDate(date.getDate() - 7);
        date.setHours(0, 0, 0);
      }
      if (filters.date == "3") {
        date.setDate(date.getDate() - 14);
        date.setHours(0, 0, 0);
      }
      const components = [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
      ].map((component) => {
        if (component < 10) {
          return `0${component}`;
        }
        return component;
      });
      args.filters.Date = {
        $gte: components.join("-"),
      };
    }
    const query = qs.stringify(
      args,

      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    fetch(`${process.env.REACT_APP_CMSURL}/api/job-opportunities?${query}`)
      .then((response) => response.json())
      .then((response) => {
        setPageCount(response?.meta?.pagination.pageCount);
        setOpportunitiesItems(response?.data ?? []);
      });
  };
  const { t } = useTranslation();
  const [sortBy, setSortBy] = React.useState(1);
  const [showFrom, setShowFrom] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    pageChange(null, 1, sortBy, form, event.target.value);
  };

  const handleChange = (event) => {
    setSortBy(event.target.value);
    pageChange(null, 1, event.target.value);
  };

  const handleShowFromChange = (event) => {
    setShowFrom(event.target.value);
  };
  const [form, setForm] = React.useState({
    locations: [],
    jobStyles: [],
    date: "0",
  });
  const clearFilters = () => {
    setForm({
      locations: [],
      jobStyles: [],
      date: "0",
    });
    setSearch("");
    pageChange(null, 1, sortBy);
  };

  return (
    <Wrapper>
      <Wrap>
        <Heading>{t("career.jobOpportunity.title")}</Heading>

        <div>
          <DropDownLabel>
            {t("career.jobOpportunity.filter.title")}
          </DropDownLabel>
          <CustomDropDown
            value={sortBy}
            onChange={handleChange}
            IconComponent={SelectIcon}
          >
            <MenuItem value={1}>
              {t("career.jobOpportunity.filter.option1")}
            </MenuItem>
            <MenuItem value={2}>
              {t("career.jobOpportunity.filter.option2")}
            </MenuItem>
          </CustomDropDown>
        </div>
      </Wrap>
      <Grid container columns={{ xs: 1, sm: 6, md: 12 }} spacing={2}>
        <Grid item xs={1} sm={6} md={4}>
          <div>
            <JobsFilters
              clearFilters={clearFilters}
              handleSearchChange={handleSearchChange}
              form={form}
              setForm={applyFilters}
            />
          </div>
        </Grid>
        <Grid item xs={1} sm={6} md={8}>
          <div>
            <JobsList jobs={OpportunitiesItems} />
            <JobsListFilterWrapper>
              {/*<PageSelectWrap>
                <DropDownLabel>{t("career.jobs.filter.title")}</DropDownLabel>
                <CustomDropDown
                  value={showFrom}
                  onChange={handleShowFromChange}
                  IconComponent={SelectIcon}
                >
                  <MenuItem value={1}>
                    {t("career.jobs.filter.option1")}
                  </MenuItem>
                  <MenuItem value={2}>
                    {t("career.jobs.filter.option2")}
                  </MenuItem>
                  <MenuItem value={3}>
                    {t("career.jobs.filter.option3")}
                  </MenuItem>
                </CustomDropDown>
                <DropDownLabel>{t("career.jobs.filter.title2")}</DropDownLabel>
              </PageSelectWrap>*/}
              <CustomPagination
                onChange={pageChange}
                count={pageCount}
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      first: FirstPageIcon,
                      last: LastPageIcon,
                    }}
                    {...item}
                  />
                )}
              />
            </JobsListFilterWrapper>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default JobOpportunity;
