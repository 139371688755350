import React from "react";
import { CustomiseCard, SupportDiv, ImageDiv, HelpWrapper, Buttons, Content, ImageContent } from "./style";
import SearchBar from "../Searchbar";
import { Para } from "../style";
import { useTranslation } from "react-i18next";

export const SupportPage = () => {
  const { t } = useTranslation();

  return (
    <HelpWrapper>
      <ImageDiv>
        <ImageContent>
          <p className="title">{t("support.helpCenter")}</p>
          <SearchBar placeholder="Search" />
          <div className="image-buttons">
            <button className="personal">{t("support.personal")}</button>
            <button className="business"> {t("support.Business")}</button>
          </div>
        </ImageContent>
      </ImageDiv>
      <SupportDiv>
        <CustomiseCard>
          <Para className="heading">{t("support.setting")}</Para>
          <Content>
            <Para>{t("support.para1")}</Para>
            <Para>{t("support.para2")}</Para>
            <Para>{t("support.para3")}</Para>
          </Content>
          <Buttons>
            <button>{t("support.view")}</button>
            <img src="/images/right-arrow.svg" alt="" />
          </Buttons>
        </CustomiseCard>
        <CustomiseCard>
          <Para className="heading">{t("support.business")}</Para>
          <Content>
            <Para>{t("support.para1")}</Para>
            <Para>{t("support.para2")}</Para>
            <Para>{t("support.para3")}</Para>
          </Content>
          <Buttons>
            <button>{t("support.view")}</button>
            <img src="/images/right-arrow.svg" alt="" />
          </Buttons>
        </CustomiseCard>
        <CustomiseCard>
          <Para className="heading">{t("support.sending")}</Para>
          <Content>
            <Para>{t("support.para1")}</Para>
            <Para>{t("support.para2")}</Para>
            <Para>{t("support.para3")}</Para>
          </Content>
          <Buttons>
            <button>{t("support.view")}</button>
            <img src="/images/right-arrow.svg" alt="" />
          </Buttons>
        </CustomiseCard>
        <CustomiseCard>
          <Para className="heading">{t("support.business")}</Para>
          <Content>
            <Para>{t("support.para1")}</Para>
            <Para>{t("support.para2")}</Para>
            <Para>{t("support.para3")}</Para>
          </Content>
          <Buttons>
            <button>{t("support.view")}</button>
            <img src="/images/right-arrow.svg" alt="" />
          </Buttons>
        </CustomiseCard>
        <CustomiseCard>
          <Para className="heading">{t("support.setting")}</Para>
          <Content>
            <Para>{t("support.para1")}</Para>
            <Para>{t("support.para2")}</Para>
            <Para>{t("support.para3")}</Para>
          </Content>
          <Buttons>
            <button>{t("support.view")}</button>
            <img src="/images/right-arrow.svg" alt="" />
          </Buttons>
        </CustomiseCard>
        <CustomiseCard>
          <Para className="heading">{t("support.sending")}</Para>
          <Content>
            <Para>{t("support.para1")}</Para>
            <Para>{t("support.para2")}</Para>
            <Para>{t("support.para3")}</Para>
          </Content>
          <Buttons>
            <button>{t("support.view")}</button>
            <img src="/images/right-arrow.svg" alt="" />
          </Buttons>
        </CustomiseCard>
        <CustomiseCard>
          <Para className="heading">{t("support.setting")}</Para>
          <Content>
            <Para>{t("support.para1")}</Para>
            <Para>{t("support.para2")}</Para>
            <Para>{t("support.para3")}</Para>
          </Content>
          <Buttons>
            <button>{t("support.view")}</button>
            <img src="/images/right-arrow.svg" alt="" />
          </Buttons>
        </CustomiseCard>
        <CustomiseCard>
          <Para className="heading">{t("support.business")}</Para>
          <Content>
            <Para>{t("support.para1")}</Para>
            <Para>{t("support.para2")}</Para>
            <Para>{t("support.para3")}</Para>
          </Content>
          <Buttons>
            <button>{t("support.view")}</button>
            <img src="/images/right-arrow.svg" alt="" />
          </Buttons>
        </CustomiseCard>
        <CustomiseCard>
          <Para className="heading">{t("support.sending")}</Para>
          <Content>
            <Para>{t("support.para1")}</Para>
            <Para>{t("support.para2")}</Para>
            <Para>{t("support.para3")}</Para>
          </Content>
          <Buttons>
            <button>{t("support.view")}</button>
            <img src="/images/right-arrow.svg" alt="" />
          </Buttons>
        </CustomiseCard>
      </SupportDiv>
    </HelpWrapper>
  );
};
