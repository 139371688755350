import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import { MainBox } from "./style";
import { useTranslation } from "react-i18next";

const OurTeams = ({ feature }) => {
  const { t } = useTranslation();
  
  return (
    <>
      <MainBox>
        <div className="flip-box">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <Card className="front-card">
                <CardContent sx={{ padding: "0" }}>
                  <Typography gutterBottom variant="h4" component="div" mt={1} className="heading">
                    {t(feature.title)}
                  </Typography>
                </CardContent>
                <CardMedia
                  sx={{
                    height: 350,
                    backgroundSize: "cover",
                    margin: 0,
                    position: "relative",
                    bottom:feature.notResponsive?"-59px":"-1px"
                    }}
                  image={feature.imagePath}
                />
              </Card>
            </div>
            <div className="flip-box-back">
              <Card className="back-card">
                <Typography gutterBottom variant="h4" component="div" mt={1} className="heading">
                  {t(feature.title)}
                </Typography>
                <Typography gutterBottom variant="p" component="div" mt={1} className="description">
                  {t(feature.description)}

                </Typography>
                {feature.description2 ?
                  (<Typography gutterBottom variant="p" component="div" mt={1} className="description">
                    {t(feature.description2)}
                  </Typography>)

                  : ('')}

              </Card>
            </div>
          </div>
        </div>
      </MainBox>
    </>
  );
};

export default OurTeams;
