import * as React from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CustomAccordion, TypographyDetails, TypographySummary } from "./style";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import Markdown from "../Strapi/Markdown";

const Accordions = (props) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {props?.accordions.map((accordion, key) => (
        <>
          <CustomAccordion
            expanded={expanded === accordion.id}
            onChange={handleChange(accordion.id)}
            key={key}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={key + "panel1bh-content"}
              id={key + "panel1bh-header-"}
            >
              <TypographySummary>{t(accordion.question)}</TypographySummary>
            </AccordionSummary>
            <AccordionDetails>
              <Markdown content={accordion.answer}></Markdown>
            </AccordionDetails>
          </CustomAccordion>

          {accordion.isDivider ? (
            <Divider
              variant="middle"
              sx={{
                margin: "36px 0",
                border: "1px solid rgba(162, 162, 163, 0.2)",
              }}
            />
          ) : (
            ""
          )}
        </>
      ))}
    </div>
  );
};

export default Accordions;
