import styled from "styled-components";

export const CardWrapper = styled.div`
  background: #ffffff;
  border-radius: 24px;
  max-width:397px;
  img{

    width: 100%;
  }

  .card-title {
    margin: 0;
    min-height: 150px;
    padding: 28px 28px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #121212;
    text-align: center;
    span {
      font-weight: 800;
      font-size: 48px;
      color: rgba(255, 147, 30, 1);
    }

  }
`;
