import styled from "styled-components";
import Tab from "@mui/material/Tab";

export const FaqTitle = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  color: #121212;

  @media screen and (max-width: 899px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const FaqTab = styled(Tab)`
  font-family: "SF Pro Text" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: #4d4d4d;
  align-items: flex-start !important;
  text-align: start !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 16px !important;
  max-width: unset !important;
  min-width: unset !important;
  min-height: unset !important;
  letter-spacing: unset !important;
  text-transform: capitalize !important;

  @media screen and (max-width: 899px) {
    font-size: 16px !important;
    line-height: 19px !important;
  }
`;
