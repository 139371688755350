import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomSelect, CustomMenuItem, FlagIcon, FlagWrapper } from "./style";
const LanguageSelector = ({ onSelect, defaultLanguage, onclose }) => {
  const { i18n } = useTranslation();
  return (
    <>
      <FlagWrapper>
        <CustomSelect
          value={defaultLanguage}
          onChange={onSelect}
          onClick={onclose}
          defaultValue={defaultLanguage}
          textDirection={i18n.dir()}
        >
          <CustomMenuItem value="en">
            <FlagIcon alt="flag" className="fluid-icon" src="/images/GB.svg" />{" "}
          </CustomMenuItem>
          {/*<CustomMenuItem value="us">*/}
          {/*  <FlagIcon alt="flag" className="fluid-icon" src="/images/US.svg" />{" "}*/}
          {/*  United States of America*/}
          {/*</CustomMenuItem>*/}
          <CustomMenuItem value="it">
            <FlagIcon alt="flag" className="fluid-icon" src="/images/It.svg" />{" "}
          </CustomMenuItem>
          {/*<CustomMenuItem value="eu">*/}
          {/*  <FlagIcon alt="flag" className="fluid-icon" src="/images/EU.svg" />{" "}*/}
          {/*  European Union*/}
          {/*</CustomMenuItem>*/}
        </CustomSelect>
      </FlagWrapper>
    </>
  );
};

export default LanguageSelector;
