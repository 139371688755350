import {
    ContentWrapper
} from "../../../../Pages/style"
import {
    StepWrapper,
    StepContainer,
    StepIcon,
    StepNumber,
    StepDetails,
    StepDetailsTitle,
    StepDetailsWrapper,
    StepDetailsWrapperIcon
} from "./styles"

const Step = ({attr}) => {
    const click = (event) => {
        if (attr.BlockId){
            //Find element and scroll to 
            const element = document.getElementById(attr.BlockId)
            if (element){
                //Get element offset
                const y = window.scrollY + element.getBoundingClientRect().y 
                window.scrollTo({
                    left: 0,
                    top: y - 200,
                    behavior: 'smooth'
                })
            }
        }
    }
    return (
        <StepWrapper>
            <StepContainer onClick={click}>
            <StepIcon>
                <img alt={attr.Icon.alternativeText} src={`${process.env.REACT_APP_CMSURL}${attr.Icon.url}`} />
            </StepIcon>
            <StepNumber className={'step'}>{attr.Step}</StepNumber>
            <StepDetails>
                <StepDetailsTitle>{attr.Title}</StepDetailsTitle>
                <StepDetailsWrapper>
                    {attr.DescriptionIcon ? <StepDetailsWrapperIcon>
                        <img alt={attr.DescriptionIcon.alternativeText} src={`${process.env.REACT_APP_CMSURL}${attr.DescriptionIcon.url}`} />
                    </StepDetailsWrapperIcon> : [] }
                    <span>{attr.Description}</span>
                </StepDetailsWrapper>
            </StepDetails>
            </StepContainer>
        </StepWrapper>
    )
}

export default Step