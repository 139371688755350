import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 36px 100px;
  background-color: #121212;
  .title {
    font-family: "SF Pro Display";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.2px;
    color: white;
    span {
      color: #ff931e;
    }
  }
  @media screen and (max-width: 900px) {
    padding: 36px 24px;
    .title {
      font-size: 32px;
      margin: 0;
      padding: 0;
    }
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 36px 0px;
  position: relative;
`
export const HeroSection = styled.div`
  display: flex;
  justify-content: center;
  p {
    padding-bottom: 36px;
  }
  .title {
    font-size: 44px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 0;
    margin: 0 0 12px 0;
    span {
      color: #ff931e;
    }
  }
  .description {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
    margin: 0 0 32px 0;
    padding: 0;
  }
  .subTitle {
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 0;
    margin: 0 0 12px 0;
    color: #ff931e;
  }
  .block {
    border-radius: 12px;
    background-color: #F1F3F5;
    margin-bottom: 36px;
    .description {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.2px;
      color: #384254;
      text-align: left;
      padding: 0 36px 0 36px;
      &--right {
        padding: 0 0 0 36px;
      }
      span {
       font-weight: bold;
      }
    }
  }
  @media screen and (max-width: 900px) {
    padding: 16px;
    .title {
      font-size: 32px;
    }
    .description {
      margin: 0;
      font-size: 16px;
    }
  } 
`;