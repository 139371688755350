import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 5.208333333333333vw;
  margin: 36px 0;

  @media screen and (max-width: 899px) {
    margin: 16px 0;
  }
`;
