import { Button, Link } from "@mui/material";
import styled from "styled-components";

export const JobInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 899px) {
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
`;

export const JobTitle = styled.p`
  margin: 0;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #121212;

  @media screen and (max-width: 899px) {
    font-size: 18px;
    line-height: 23px;
  }
`;

export const JobSocialButton = styled(Button)`
  background-color: rgba(162, 162, 163, 0.08) !important;
  border-radius: 16px !important;
  font-family: "SF Pro Text" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center !important;
  color: #4d4d4d !important;
  box-shadow: none !important;
  padding: 12px 24px !important;

  @media screen and (max-width: 899px) {
    padding: 12px !important;
  }

  @media screen and (max-width: 346px) {
    margin-bottom: 10px !important;
  }
  &:hover {
    background-color: rgba(162, 162, 163, 0.3) !important;
  }
`;

export const JobSocialMedia = styled.div``;

export const JobPost = styled.div`
  margin: ${(props) => props.margin ?? "0"};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;

  @media screen and (max-width: 899px) {
    flex-wrap: wrap;
  }
`;

export const JobPostWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  span {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #8f9aa8;

    @media screen and (max-width: 899px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

export const JobDescription = styled.div`
  margin-bottom: 16px;
`;

export const JobDescriptionTitle = styled.p`
  margin: 0 0 4px 0;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #121212;

  @media screen and (max-width: 899px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const CustomUL = styled.ul`
  padding-inline-start: ${(props) => props.paddingInlineStart ?? "initial"};
`;

export const JobDescriptionPoint = styled.p`
  margin: 0 0 4px 0;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #686c72;

  ol {
    padding-inline-start: 20px;
    font-weight: 600;
    letter-spacing: -0.2px;
    color: #4d4d4d;
  }

  ul {
    list-style-type: disc;
    margin: 16px 0;
    font-weight: 400;
    letter-spacing: -0.2px;
    color: #4d4d4d;
  }

  @media screen and (max-width: 899px) {
    font-size: 14px;
    line-height: 18px;

    ol,
    ul {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const JobLink = styled(Link)`
  margin: 16px 0 0 0 !important;
  font-family: "SF Pro Text" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #f37f00 !important;
  display: flex;
  align-items: center;
  gap: 10px;
`;
