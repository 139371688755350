import { Grid } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import EmiCard from "../Components/Card/EmiCard";
import FamilyWealthCard from "../Components/Card/FamilyWealthCard";
import SMEBank from "../Components/Card/SMEBank";
import CardWithImage from "../Components/CardWithImage";
import MainHeroSection from "../Components/MainHeroSection";
import { Container, SectionTitle } from "../Components/style";
import WhyDigiDoe from "../Components/WhyDigiDoe";
import { MainContainer } from "./style";
import CookiesConcentPopup from "../Components/CookiesConcentPopup";

const Main = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <MainHeroSection />
      <Container>
        <SectionTitle textAlign="center">{t("mainPage.bankingService.title")}</SectionTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="center" alignItems="center">
            <EmiCard />
          </Grid>
          <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="center" alignItems="center">
            <FamilyWealthCard />
          </Grid>
          <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="center" alignItems="center">
            <SMEBank />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <SectionTitle textAlign="center">{t("mainPage.valueProposition.title")}</SectionTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="center" alignItems="center">
            <CardWithImage cardTitle={<Trans i18nKey="mainPage.valueProposition.box1" />} imgPath="/images/onlineBanking.svg" />
          </Grid>
          <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="center" alignItems="center">
            <CardWithImage cardTitle={t("mainPage.valueProposition.box2")} imgPath="/images/Frame 48095698.svg" />
          </Grid>
          <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="center" alignItems="center">
            <CardWithImage cardTitle={t("mainPage.valueProposition.box3")} imgPath="/images/Frame 48095700.svg" />
          </Grid>
        </Grid>
      </Container>
      {/*<CookiesConcentPopup />*/}
    </MainContainer>
  );
};

export default Main;
