import React from "react";
import BoxFrame from "../BoxFrame";
import { Breadcrumb, Heading, Para } from "../style";
import { Wrapper } from "./style";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Link } from "@mui/material";

const ProductHeroSection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BoxFrame alignImage="right" dataImage="../images/Group21714.svg">
        <Breadcrumb style={{ padding: "18px 0" }}>
          <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              <img src="/images/home2.svg" alt="" />
              {t("breadCrumb.main")}
            </Link>
            <Link underline="hover" color="inherit" href="/modules" className="active">
              {t("breadCrumb.modules")}
            </Link>
          </Breadcrumbs>
        </Breadcrumb>
        <Heading margin="16px 0">{t("product.heroSection.title")}</Heading>
        <Para margin="0 0 16px 0">{t("product.heroSection.desc")}</Para>
      </BoxFrame>
    </Wrapper>
  );
};

export default ProductHeroSection;
