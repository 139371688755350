import { Grid } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import OurTeams from "./OurTeams";
import { CardWrapper, FeatureHeading, ServiceHeroSection, ServiceTitle, Wrapper } from "./style";
import {FrameContent, FrameTitle} from "../style";
import BoxFrame from "../BoxFrame";

const Service = () => {
  const { t } = useTranslation();
  const features = [
    {
      title: "services.features.box2.title",
      description: "services.features.box2.desc",
      imagePath: "/images/apiIntegration.svg",
      notResponsive: true,
    },
    {
      title: "services.features.box3.title",
      description: "services.features.box3.desc1",
      description2: "services.features.box3.desc2",
      imagePath: "/images/foreignexchange.svg",
    },
    {
      title: "services.features.box4.title",
      description: "services.features.box4.desc",
      imagePath: "/images/amltransition.svg",
    },
  ];

  return (
    <Wrapper>
      <BoxFrame alignImage="right" dataImage="/images/customerPyramid.svg">
        <FrameTitle>{t("bankingService.title")}</FrameTitle>
        <FrameContent>
          <p>{t("bankingService.desc1")}</p>
          <p>{t("bankingService.desc2")}</p>
        </FrameContent>
      </BoxFrame>
      <ServiceTitle>
        <Trans i18nKey={"services.title"} />
      </ServiceTitle>
      <ServiceHeroSection />
      <FeatureHeading>
        <h1>{t("services.features.title")}</h1>
        <p>
          <Trans i18nKey={"services.features.desc"} />
        </p>
      </FeatureHeading>
      <CardWrapper>
        <Grid container spacing={2} display="flex" justifyContent={'space-between'} >
          {features.map((feature) => (
            <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="center" alignItems="center">
              <OurTeams feature={feature} key={feature.title} />
            </Grid>
          ))}
        </Grid>
      </CardWrapper>
    </Wrapper>
  );
};

export default Service;
