import { TextareaAutosize } from "@mui/material";
import styled from "styled-components";

export const CustomiseTextarea = styled(TextareaAutosize)`
  width: 100%;
  resize: none;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
  padding: 16.5px 14px;
  outline: none;
  background: transparent;

  &::placeholder {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #c4c4c4;
  }
`;
