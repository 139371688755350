import React from "react";
import styled from "styled-components";
import BankingService from "../Components/BankingService";
import DigitalBank from "../Components/BankingService/DigitalBank";
import { MainContainer } from "./style";
import { Breadcrumbs, Link } from "@mui/material";
import { Breadcrumb } from "../Components/style";
import { useTranslation } from "react-i18next";

const Solutions = () => {
  const { t } = useTranslation();
 
  return (
    <MainContainer spacing={true}>
      <Container>
        <Breadcrumb>
          <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              <img src="/images/home2.svg" alt="" />
              {t("breadCrumb.main")}
            </Link>
            <Link underline="hover" color="inherit" href="/solutions" className="active">
              {t("breadCrumb.solution")}
            </Link>
          </Breadcrumbs>
        </Breadcrumb>
        <BankingService />
        <DigitalBank />
      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
`;

export default Solutions;
