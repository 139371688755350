import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import { Typography } from "@mui/material";

export const CustomAccordion = styled(Accordion)`
  border-radius: 24px !important;
  margin-bottom: 16px !important;
  box-shadow: none !important;

  &::before {
    background-color: transparent !important;
  }
`;

export const TypographySummary = styled(Typography)`
  font-family: "SF Pro Text" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: #121212 !important;

  span {
    padding: 8px 12px;
    background: rgba(162, 162, 163, 0.08);
    border-radius: 8px;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4d4d4d;
    margin-left: 8px;
  }

  @media screen and (max-width: 899px) {
    font-size: 16px;
  }
`;
