import {
    ContentWrapper
} from "../../../../Pages/style"
import {
    ButtonsGroupWrapper
} from "./styles"
import Button from "./../Buttons/Button"

const ButtonsGroup = ({attr, emit}) => {
    return (
        <ContentWrapper >
            <ButtonsGroupWrapper Align={attr.Align}>
                {attr.Buttons.map((button)=>{
                    return <Button attr={button} onClick={emit}/>
                })}
            </ButtonsGroupWrapper>
        </ContentWrapper>
    )
}

export default ButtonsGroup