import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 5.208333333333333vw 105px 5.208333333333333vw;
`;

export const DigitalBankWrapper = styled.div`
  padding: 105px 5.208333333333333vw;
`;

export const RightContent = styled.div`
  height: 413px;
  width: 100%;
  background: url(/images/Circle.svg) no-repeat;
  background-position: right top;
  position: relative;
  .iphone-img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 1200px) {
    margin-top: 170px;
    background: url(/images/Group21681.svg) no-repeat;
    background-size: cover;
  }

  @media (max-width: 450px) {
    .iphone-img {
      width: 100%;
    }
  }
`;

export const TitleBlock = styled.div`
  height: 50%;
  width: 100%;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding: 56px 0 46px 56px;
  .title {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: -0.4px;
    color: #121212;
  }
  .time {
    margin: 0;
    font-style: normal;
    font-weight: 800;
    letter-spacing: -0.2px;
    color: #ff931e;
    span {
      font-weight: 700;
      font-size: 36px;
    }
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0px;
`;

export const ButtonWithIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(162, 162, 163, 0.08);
  border: none;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: #384254;
  padding: 8px 25px;
  max-width: 97px;
  width: fit-content;
  img {
    width: 20px;
    padding-right: 6px;
  }
`;

export const BtnBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 30%;
  width: 100%;
`;