import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Breadcrumb, Heading, Para, ParaTitle } from "../../Components/style";
import { MainContainer } from "../style";
import { Breadcrumbs, Link } from "@mui/material";
import {useParams} from "react-router-dom";

const PolicyUs = () => {
  const { t } = useTranslation();
  const { location } = useParams();
  return (
    <MainContainer spacing={true}>
      <Container>
        <Heading margin="0 0 36px 0">{t("privacyPolicy.heading")} US</Heading>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que1")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans1Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans1Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans1Points.3")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que2")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans2Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans2Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans2Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans2Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans2Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans2Points.6")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans2Points.7")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans2Points.8")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans2Points.9")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que3")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans3Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans3Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans3Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans3Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans3Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans3Points.6")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que4")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans4Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans4Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans4Points.3")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que5")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans5Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que6")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans6Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que7")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.6")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.7")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.8")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.9")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.10")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.11")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.12")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.13")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.14")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.15")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans7Points.16")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que8")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans8Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que9")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans9Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que10")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans10Points.1")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que11")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans11Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans11Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans11Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans11Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans11Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans11Points.6")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans11Points.7")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans11Points.8")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans11Points.9")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que12")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.6")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.7")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.8")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.9")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.10")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.11")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans12Points.12")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que13")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans13Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans13Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans13Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans13Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans13Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans13Points.6")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que14")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.6")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.7")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.8")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.9")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.10")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.11")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.12")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.13")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans14Points.14")}</Para>

        <ParaTitle margin="0 0 8px 0">{t("privacyPolicy.questions.que15")}</ParaTitle>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.1")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.2")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.3")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.4")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.5")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.6")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.7")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.8")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.9")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.10")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.11")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.12")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.13")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.14")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.15")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.16")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.17")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.18")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.19")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.20")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.21")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.22")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.23")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.24")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.25")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.26")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.27")}</Para>
        <Para margin="0 0 16px 0">{t("privacyPolicy.questions.ans15Points.26")}</Para>

        <Para margin="0 0 16px 0">&nbsp;</Para>

      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
  padding: 0 5.208333333333333vw;
`;

export default PolicyUs;
