import React from "react";
import { CustomiseCard } from "../style";
import SearchBar from "../Searchbar";
import { Divider } from "@mui/material";
import { ClearFilterLink, FiltersTitle, TitleWrap } from "./style";
import FiltersAccordion from "../FiltersAccordion";
import CustomCheckbox from "../CustomCheckbox";
import { DropDownLabel, CustomDropDown } from "../JobOpportunity/style";
import { SelectIcon } from "../../Icons/SelectIcon";
import { Grid, MenuItem, PaginationItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { json } from "react-router-dom";

const JobsFilters = ({ form, setForm, handleSearchChange, clearFilters }) => {
  const [LocationItems, setLocationItems] = React.useState([]);
  const [JobStyleItems, setJobStyleItems] = React.useState([]);
  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_CMSURL}/api/locations`)
      .then((response) => response.json())
      .then((data) => {
        setLocationItems(data?.data ?? []);
      });
    fetch(`${process.env.REACT_APP_CMSURL}/api/job-styles`)
      .then((response) => response.json())
      .then((data) => {
        setJobStyleItems(data?.data ?? []);
      });
  }, []);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const formcopy = { ...form };
    formcopy.date = event.target.value;
    setForm(formcopy);
    //setSortBy(event.target.value);
  };

  let filters = [
    {
      id: 1,
      summary: "career.jobsFilters.filter1.title",
      details: LocationItems.map((location) => {
        return (
          <CustomCheckbox
            onChange={(e) => {
              const formcopy = { ...form };
              const value = e.target.checked;
              if (value == true) {
                if (formcopy.locations.indexOf(location.documentId) == -1) {
                  formcopy.locations.push(location.documentId);
                }
              }
              if (value == false) {
                const index = formcopy.locations.indexOf(location.documentId);
                if (index >= 0) {
                  formcopy.locations.splice(index, 1);
                }
              }
              setForm(formcopy);
            }}
            isChecked={false}
            label={location.Location}
          />
        );
      }),

      isDivider: true,
    },
    {
      id: 2,
      summary: "career.jobsFilters.filter2.title",
      details: JobStyleItems.map((jobStyle) => {
        return (
          <CustomCheckbox
            onChange={(e) => {
              const formcopy = { ...form };
              const value = e.target.checked;
              if (value == true) {
                if (formcopy.jobStyles.indexOf(jobStyle.documentId) == -1) {
                  formcopy.jobStyles.push(jobStyle.documentId);
                }
              }
              if (value == false) {
                const index = formcopy.jobStyles.indexOf(jobStyle.documentId);
                if (index >= 0) {
                  formcopy.jobStyles.splice(index, 1);
                }
              }
              setForm(formcopy);
            }}
            isChecked={false}
            label={jobStyle.Title}
          />
        );
      }),
      isDivider: true,
    },
    {
      id: 3,
      summary: "career.jobsFilters.filter3.title",
      details: (() => {
        return (
          <div>
            <DropDownLabel>{t("career.datePosted.filter.title")}</DropDownLabel>
            <CustomDropDown
              value={form.date}
              onChange={handleChange}
              IconComponent={SelectIcon}
            >
              <MenuItem value={0}>
                {t("career.datePosted.filter.option")}
              </MenuItem>
              <MenuItem value={1}>
                {t("career.datePosted.filter.option1")}
              </MenuItem>
              <MenuItem value={2}>
                {t("career.datePosted.filter.option2")}
              </MenuItem>
              <MenuItem value={3}>
                {t("career.datePosted.filter.option3")}
              </MenuItem>
            </CustomDropDown>
          </div>
        );
      })(),
    },
  ];
  return (
    <CustomiseCard padding="24px">
      <TitleWrap>
        <FiltersTitle>{t("career.jobsFilters.title")}</FiltersTitle>
        {/* <ClearFilterLink onClick={clearFilters} >
          {t("career.jobsFilters.clearFilterLink")}
        </ClearFilterLink>*/}
      </TitleWrap>
      <Divider
        variant="middle"
        sx={{ margin: "16px 0", border: "1px solid #F1F3F5" }}
      />
      <SearchBar handleChange={handleSearchChange} laceholder="Search a job" />
      <Divider
        variant="middle"
        sx={{ margin: "16px 0", border: "1px solid #F1F3F5" }}
      />
      <FiltersAccordion accordions={filters} />
    </CustomiseCard>
  );
};

export default JobsFilters;
