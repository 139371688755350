import React from "react";
import { Grid } from "@mui/material";
import { Logo } from "../Navbar/style";
import { Container, FooterContent, SocialWrapper, Wrapper, GridDiv, GridWrapper, LogoImg, LogoBlocks } from "./style.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LanguageSelector from "../Navbar/LanguageSelector";
import { useState } from "react";

const Footer = () => {
  const { i18n, t } = useTranslation();
  const [location, setLocation] = useState("en");

  const locationSelectHandler = (event) => {
    setLocation(event.target.value);
    i18n.changeLanguage(event.target.value);
  };
  return (
    <Container langDirection={i18n.dir()}>
      <GridDiv>
        <GridWrapper>
          <Logo src="/images/Logo.svg" />
          <SocialWrapper>
            <Link to={"https://www.linkedin.com/company/digidoe"}><img src="/images/linkedin.svg" alt="linkedin-logo" /></Link>
            <Grid container xs={12} sm={3} md={3} lg={3}>
              <Link to={"mailto: info@digidoe.com"} className={"mailLink"}>
                <Wrapper>
                  <img src="/images/sms.svg" alt="email-logo" />
                  <p>{t("footer.email")}</p>
                </Wrapper>
              </Link>
            </Grid>
          {/*<Grid container xs={12} sm={12} md={6}>*/}
          {/*  <Wrapper>*/}
          {/*    <p>*/}
          {/*      {" "}*/}
          {/*      {<LanguageSelector defaultLanguage={location} onSelect={locationSelectHandler} location={location} />}*/}
          {/*    </p>*/}
          {/*  </Wrapper>*/}
          {/*</Grid>*/}
          </SocialWrapper>
          <FooterContent>
            <p>{t("footer.content")}</p>
            <p>{t("footer.info")}</p>
            <p>© DigiDoe {new Date().getFullYear()}. All rights reserved.</p>
          </FooterContent>
        </GridWrapper>
        <GridWrapper>
          {/*<div className="heading">*/}
          {/*  <Link to={"/modules"} className={"link"}><b>{t("footer.Modules")}</b></Link>*/}
          {/*</div>*/}
          <ul>
            <Link to={"/modules"} className={"link"}><b>{t("footer.Modules")}</b></Link>

          {/*  <b>{t("footer.useCase")}</b>*/}
          {/*  <li>*/}
          {/*    <Link to={"/useCase/emis"} className="link">*/}
          {/*      {t("footer.forEmis")}*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <Link to={"/useCase/familyOffices"} className="link">*/}
          {/*      {t("footer.forFamily")}*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*  <li>*/}
          {/*    <Link to={"/useCase/banks"} className="link">*/}
          {/*      {t("footer.forBanks")}*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          </ul>
          <ul>
            <b> {t("footer.legal")} </b>
            {/*<li>
              <Link to={"/news"} className="link">
                {t("footer.news")}
              </Link>
            </li>
            <li>
              <Link to={"/support"} className="link">
                {t("footer.helpCenter")}
              </Link>
            </li>
            <li>
              <Link to={"/faq"} className="link">
                {t("footer.faq")}
              </Link>
            </li>*/}
            <li>
              {/*<a href={`/pdfs/Terms_and_Conditions_${location}.pdf`} target="_blank" rel="noopener noreferrer" className="link">*/}
              {/*  {t("footer.termsAndConditions")}*/}
              {/*</a>*/}
              <a href={`/pdfs/Terms_and_Conditions_en.pdf`} target="_blank" rel="noopener noreferrer" className="link">
                {t("footer.termsAndConditions")}
              </a>
            </li>
            <li>
              {/*<a href={`/pdfs/Privacy_Policy_${location}.pdf`} target="_blank" rel="noopener noreferrer" className="link">*/}
              {/*  {t("footer.privacyPolicy")}*/}
              {/*</a>*/}
              <a href={`/pdfs/Privacy_Policy_en.pdf`} target="_blank" rel="noopener noreferrer" className="link">
                {t("footer.privacyPolicy")}
              </a>
            </li>
            <li>
              <a href={`/pdfs/Pricing_Structure_for_Retail_Clients.pdf`} target="_blank" rel="noopener noreferrer" className="link">
                {t("footer.pricing")}
              </a>
            </li>
            <li>
              {/*<a href={`/pdfs/Cookie_Policy_${location}.pdf`} target="_blank" rel="noopener noreferrer" className="link">*/}
              {/*  {t("footer.cookies")}*/}
              {/*</a>*/}
              <a href={`/pdfs/Cookie_Policy_en.pdf`} target="_blank" rel="noopener noreferrer" className="link">
                {t("footer.cookies")}
              </a>
            </li>
          </ul>
        </GridWrapper>
        <GridWrapper>
          {/*<div className="heading">*/}

          {/*</div>*/}
          <ul>
            <Link to={"/services"} className={"link"}><b>{t("footer.services")}</b></Link>
            <li>
              <Link to={"https://www.cbdcaas.org/#"} className="link">
                {t("footer.cbs")}
              </Link>
            </li>
          </ul>

          <LogoBlocks>
            <Link to={"https://apps.apple.com/gb/app/digidoe-finance-solutions/id6443841487"} target="_blank" className={"link"}>
              <LogoImg src="images/AppStoreLogo.svg" alt="news-single" />
            </Link>
            <Link to={"https://play.google.com/store/apps/details?id=com.digidoe.businessbanking&pli=1"} target="_blank" className={"link"}>
              <LogoImg src="images/GooglePlayLogo.svg" alt="news-single"  />
            </Link>
          </LogoBlocks>
        </GridWrapper>

        <GridWrapper>
          <ul>
          {/*<div className="heading">*/}
          {/*  */}

          {/*</div>*/}
            <Link to={"/aboutUs"} className="link">
              <b>{t("footer.aboutUs")}</b>
            </Link>
            {/*<li>*/}
            {/*  <Link to={"/career"} className="link">*/}
            {/*    {t("footer.careers")}*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to={"/contactUs"} className="link">*/}
            {/*    {t("footer.contactUs")}*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </ul>
        </GridWrapper>
      </GridDiv>
    </Container>
  );
};

export default Footer;
