import * as React from "react";
import { InputDiv, Input } from "./style";


// Initialize MeetingIDi with a value of '' (use whatever you want)

const SearchBar =  ( props ) => {
  
  return (
    <InputDiv margin={props.margin} width={props.width}>
      <Input
        type="text"
        name=""
        id=""
        placeholder={props.placeholder}
        onChange={props.handleChange}
      />
      <img
        className="search"
        src="/images/search-zoom-in.svg"
        alt="not-found"
      />
    </InputDiv>
  );
};

export default SearchBar;
