import styled from "styled-components";

export const SupportDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 170px;
  gap: 24px;
  @media screen and (max-width: 899px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
  @media screen and (max-width: 567px) {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 207px;
  }
`;

export const HelpWrapper = styled.div`
  padding: 0 5.20833vw;
  background: #f1f3f5;
`;

export const ImageDiv = styled.div`
  background: white;
  height: 420px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 24px;
  margin-bottom: 56px;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .image-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .personal {
      color: #ff931e;
      background: rgb(255, 147, 30, 16%);
      border: none;
      border-radius: 15px;
      height: 51px;
      width: 123px;
      cursor: pointer;
    }
    .business {
      background: #a2a2a314;
      border: none;
      border-radius: 15px;
      height: 51px;
      width: 123px;
      cursor: pointer;
    }
  }
  .inputs {
    width: 100%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 899px) {
      width: 400px;
    }
    @media screen and (max-width: 567px) {
      width: 320px;
    }
    .input {
      padding: 15px;
      width: 100%;
      border: 1px solid gray;
      border: 1px solid #e5e5e5;
      border-radius: 12px;
      outline: none;
    }
    .search {
      position: relative;
      right: 30px;
    }
  }
`;

export const ImageContent = styled.div`
  width: 50%;
  position: relative;
  text-align: center;
  display: grid;
  gap: 20px;
  .title {
    font-weight: 700;
    font-size: 44px;
    margin: 0;
    line-height: 53px;
    @media screen and (max-width: 899px) {
      font-size: 24px;
      margin: 0;
    }
  }

  @media screen and (max-width: 567px) {
    width: 100%;
    padding: 0 1em;
  }
`;

export const CustomiseCard = styled.div`
  border-radius: 24px;
  width: 100%;
  padding: 28px 28px 30px 28px;
  display: grid;
  gap: 10px;
  background: white;
  .heading {
    font-weight: 700;
    font-size: 24px;
    margin: 0;
    color: #121212;
    line-height: 29px;
  }
`;

export const Content = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #686c72;
  display: grid;
  gap: 10px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    background: transparent;
    color: orange;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
  }
`;
