import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 28px 5.20833vw;
`;

export const TabBtn = styled.button`
  background: ${(props) => (props.active ? "#FF931E" : "#FFFFFF")};
  color: ${(props) => (props.active ? "#FFFFFF" : "#121212")};
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
`;

export const PrevNextBtn = styled.button`
  border-radius: 8px;
  background: #ffffff;
  border: none;
  padding: 8px;
  cursor: pointer;
`;
