import React from "react";

export const SelectIcon = (props) => {
  return (
    <svg
      width="18"
      height="8"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.19591 1.02855C8.45626 0.768204 8.87837 0.768204 9.13872 1.02855C9.39907 1.2889 9.39907 1.71101 9.13872 1.97136L5.13872 5.97136C4.88634 6.22375 4.47995 6.23258 4.21683 5.99139L0.216834 2.32473C-0.0545788 2.07593 -0.0729132 1.65422 0.175881 1.38281C0.424676 1.1114 0.846388 1.09306 1.1178 1.34186L4.64727 4.5772L8.19591 1.02855Z"
        fill="#98999A"
      />
    </svg>
  );
};
