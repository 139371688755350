import {
    ButtonWrapper,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary,
    ButtonLink,
    ButtonTertiary,
    ButtonClear,
    LinkPrimary,
    LinkSecondary,
    LinkLink,
    LinkTertiary,
    LinkClear,
} from "./styles"

const Button = ({attr,onClick}) => {
    if (attr.Enabled){
        let Button = ButtonPrimary
        let Link = LinkPrimary
        switch(attr.Type.toLowerCase()){
            case 'secondary':
                Button = ButtonSecondary
                Link = LinkSecondary
                break;
            case 'tertiary':
                Button = ButtonTertiary
                Link = LinkTertiary
                break;
            case 'link':
                Button = ButtonLink
                Link = LinkLink
                break;
            case 'clear':
                Button = ButtonClear
                Link = LinkClear
                break;
        }
        const click = () => {
            if (attr.Action == 'File'){
                //Open blob
                var a = document.createElement('A');
                var filePath = `${process.env.REACT_APP_CMSURL}${attr.attachment?.url}`
                a.href = filePath;
                a.target = "_blank"
                a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                return
            }
            onClick(attr)
        }
        const ButtonTag = attr.Action == 'Link' ? Link : Button
        return (
            <ButtonTag size={attr.Size} onClick={click} target="_blank" to={attr?.href ? attr.href : ''}>
                <ButtonContainer>
                    {attr.Icon ? <img alt={attr.Icon.alternativeText} src={`${process.env.REACT_APP_CMSURL}${attr.Icon.url}`}/> : []}
                    <span>{attr.Label}</span>
                </ButtonContainer>
            </ButtonTag>
        )
    }
    return (
        <ButtonWrapper></ButtonWrapper>
    )
}

export default Button