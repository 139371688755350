import React from "react";
import { Menu } from "@mui/material";
import { MenuItem } from "../Navbar/style";
import { MenuItemBox, DropdownMenu, DropdownItem } from "./style";

const PopperDropdown = ({ page, onClose }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <MenuItem
        color="red"
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={handleClick}
      >
        <MenuItemBox primary={anchorEl}>
          {page.name} {<img src="/images/Path 94.svg" alt="not-found" />}
        </MenuItemBox>
      </MenuItem>
      <Menu
        sx={{ top: "35px" }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        <DropdownMenu>
          {page.options.map((item) => {
            return (
              <MenuItem to={item.navigate} key={item.name} onClickCapture={onClose} onClick={onClose}>
                <DropdownItem>
                  {item.imageUrl && <img src={item.imageUrl} alt="nav-icon" />}
                  <span>
                    <p className="title">{item.name}</p>
                    <p className="name-secondary">{item.nameSecondary}</p>
                  </span>
                  {item.imageSecondary && (
                    <img
                      className="image-secondary"
                      src={item.imageSecondary}
                      alt="left-arrow"
                    />
                  )}
                </DropdownItem>
              </MenuItem>
            );
          })}
        </DropdownMenu>
      </Menu>
    </>
  );
};

export default PopperDropdown;
