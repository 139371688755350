import styled from "styled-components";
import { Link } from "react-router-dom";

export const MobileNavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  //margin: 0px 1.667vw;
  padding: 0 3.832291666666667vw;
  border-radius: 8px;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  a {
    padding: 0px;
    text-decoration: none;
    color: rgb(77, 77, 77);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  @media only screen and (max-width: 1215px) {
    padding: 0 25px;
  }
  .nav-icon {
    width: 30px;
    height: 30px;
  }
`;

export const MenuBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  /* .flag-img, img{
    width: 15px;
  } */
`;

export const MenuItem = styled(Link)`
  text-decoration: none;
  color: #4d4d4d;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  &:hover {
    color: #ff931e;
  }
`;

export const FlexDiv = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close {
    width: 30px;
    height: 30px;
  }
  .logo {
    height: 30px;
    width: 95px;
  }
`;
