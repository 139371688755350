import * as React from "react";
import CareerHeroSection from "../Components/CareerHeroSection";
import styled from "styled-components";
import JobOpportunity from "../Components/JobOpportunity";
import { MainContainer } from "./style";

const Career = () => {
  return (
    <MainContainer>
      <Container>
        <CareerHeroSection />
        <JobOpportunity />
      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
`;

export default Career;
