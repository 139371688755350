import React from "react";
import BoxFrame from "../BoxFrame";
import { FrameTitle, FrameContent, FrameCard } from "../style";
import { Wrapper } from "./style";
import { useTranslation } from "react-i18next";

const WealthManagement = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BoxFrame alignImage="right" dataImage="/images/Frame48095676.svg">
        <FrameTitle>{t("wealthManagement.title")}</FrameTitle>
        <FrameContent>
          <p>{t("wealthManagement.desc1")}</p>
          <FrameCard>
            <img src="/images/A.svg" alt="not-found" />
            <p>{t("wealthManagement.card.desc1")}</p>
          </FrameCard>
          <FrameCard>
            <img src="/images/B.svg" alt="not-found" />
            <p>{t("wealthManagement.card.desc2")}</p>
          </FrameCard>
          <p>{t("wealthManagement.desc2")}</p>
        </FrameContent>
      </BoxFrame>
    </Wrapper>
  );
};

export default WealthManagement;
