import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 36px 100px;
  background-color: white;
  @media screen and (max-width: 900px) {
    padding: 0px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 100px 0px 36px 0px;
  position: relative;
  @media screen and (max-width: 900px) {
    padding: 16px 0;
    flex-wrap: wrap;
    justify-content: center;
    img {
      margin: 12px 0;
      order: 2;
      height: auto;
      &:nth-child(1) {
        width: 50%
      }
      &:nth-child(2) {
        width: 40%
      }
    }
    
  }
`
export const AbsoluteWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 20.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  .title {
    color: var(--White, #FFF);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.2px;
  }
  .moto {
    max-width: 90%;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 53px;
    text-align: center;
    letter-spacing: -0.2px;
    color: white;
    span {
      color: #ff931e;
    }
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`
export const HeroSection = styled.div`
  display: flex;
  justify-content: center;
  p {
    padding-bottom: 36px;
  }
  .title {
    max-width: 818px;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 53px;
    text-align: left;
    letter-spacing: -0.2px;
    span {
      color: #ff931e;
    }
  }
  .block {
    border-radius: 12px;
    background-color: #F1F3F5;
    margin-bottom: 36px;
    .description {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.2px;
      color: #384254;
      text-align: left;
      padding: 0 36px 0 36px;
      &--right {
        padding: 0 0 0 36px;
      }
      span {
       font-weight: bold;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .title {
      font-size: 32px;
      line-height: normal;
    }
    p {
      padding: 16px;
      margin-top: 0;
    }
    .block {
      margin: 0 16px 36px;
      .description {
        padding: 16px;
        margin: 0;
        font-size: 16px;
        &--right {
          //padding: 0 36px;
        }
      }
    }
  }
`;

export const HeroSectionImg = styled.div`
  min-height: 20.5vw;
  max-width: 100%;
  font-family: "SF Pro Text";
  font-style: normal;
  background-image: url(/images/AboutUsMoto.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 64px 0px;
  .text__container {
    width: 100%;
    height: 20.5vw;
    position: relative;
    p {
      width: fit-content;
    }
  }
  @media screen and (max-width: 900px) {
    background-image: url(/images/AboutUsMoto_mob.svg);
    background-size: contain;
    height: 54vw;
    margin: 16px 0;
    .text__container {
      display: none;
    }
  }
`;
