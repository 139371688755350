import styled from "styled-components";

export const TimeLineBtn = styled.div`
  display: flex;
  justify-content: center;
  button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #8f9aa8;
    border: 2px solid #8f9aa8;
    border-radius: 36px;
    padding: 14.5px 24px;
  }
`;

export const TimeLine = styled.div`
  .timeline {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 15px 0;
  }

  .timeline::after {
    content: "";
    position: absolute;
    width: 2px;
    background: #8f9aa8;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -1px;
  }

  .container {
    padding: 15px 30px;
    position: relative;
    background: inherit;
    width: 50%;
  }

  .container.left {
    left: 0;
  }

  .container.right {
    left: 50%;
  }

  .container::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
    right: -12px;
    background: #ffffff;
    border: 8px solid #ff931e;
    border-radius: 12px;
    z-index: 1;
  }

  .container.right::after {
    left: -12px;
  }

  .container::before {
    content: "";
    position: absolute;
    width: 76px;
    height: 2px;
    top: calc(50% - 1px);
    right: 8px;
    background: #384254;
    z-index: 1;
  }

  .container.right::before {
    left: 8px;
  }

  .container .icon {
    position: absolute;
    display: inline-block;
    width: 48px;
    height: 48px;
    padding: 9px 0;
    top: calc(50% - 25px);
    background: url("/images/Piont.svg") center no-repeat;
    text-align: center;
    font-size: 18px;
    color: #006e51;
    z-index: 1;
  }

  .container.left .icon {
    right: 68px;
  }

  .container.right .icon {
    left: 68px;
  }

  .container .content {
    padding: 16px;
    background: #ffffff;
    position: relative;
    border-radius: 24px;
  }

  .content.left {
    margin-right: 61px;
  }

  .content.right {
    margin-left: 61px;
  }

  @media (max-width: 910px) {
    .timeline::after {
      left: 25px;
    }

    .container {
      width: 100%;
      padding-left: 63px;
      padding-right: 30px;
    }

    .container.right {
      left: 0%;
    }

    .container.left::after,
    .container.right::after {
      left: 13px;
    }

    .container.left::before,
    .container.right::before {
      left: 30px;
    }

    .container.left .icon,
    .container.right .icon {
      right: auto;
      left: 90px;
    }

    .content.right {
      margin-left: 51px;
    }
    .content.left {
      margin-left: 51px;
      margin-right: 0px;
    }
  }

`;

export const MileStoneContent = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: -0.4px;
  color: #121212;
  @media (max-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 910px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const MileStoneDate = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  padding-left: 5px;
  span {
    font-size: 44px;
    font-weight: 700;
    line-height: 48px;
  }

  @media (max-width: 910px) {
    font-size: 16px;
    line-height: 17px;
    span {
      font-size: 16px;
      line-height: 26px;
    }
  }
`;
