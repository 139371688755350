import React from "react";
import BoxFrame from "../BoxFrame";
import { Heading, Para, CustomiseCard, Breadcrumb } from "../style";
import { CareerCardWrapper, Wrap, Wrapper } from "./style";
import { Trans, useTranslation } from "react-i18next";
import SearchBar from "../Searchbar";
import { Breadcrumbs, Link } from "@mui/material";

const CareerHeroSection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BoxFrame alignImage="right" dataImage="../images/Frame48095583.svg">
        <Breadcrumb style={{ padding: "18px 0" }}>
          <Breadcrumbs
            separator={
              <img src="/images/breadcrumb-icon.svg" alt="not-found" />
            }
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/">
              <img src="/images/home2.svg" alt="" />
              {t("breadCrumb.main")}
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/career"
              className="active"
            >
              {t("breadCrumb.career")}
            </Link>
          </Breadcrumbs>
        </Breadcrumb>

        <Heading margin="16px 0">
          <Trans>{t("career.heroSection.title")}</Trans>
        </Heading>
        <Para margin="0 0 16px 0">{t("career.heroSection.desc")}</Para>
        {/*<SearchBar
          margin="16px 0 50px 0"
          placeholder={t("career.heroSection.searchPlaceholder")}
        />*/}
        <CustomiseCard
          background="rgba(162, 162, 163, 0.08)"
          padding="36px"
          margin="0 0 16px 0"
        >
          <CareerCardWrapper>
            <Wrap>
              <img src="../images/ComputersLiner.svg" alt="not-found" />
              <div>
                <p>{t("career.heroSection.card.title1")}</p>
                <span>{t("career.heroSection.card.desc1")}</span>
              </div>
            </Wrap>
            <Wrap>
              <img src="../images/shopping-bag.svg" alt="not-found" />
              <div>
                <p>{t("career.heroSection.card.title2")}</p>
                <span>{t("career.heroSection.card.desc2")}</span>
              </div>
            </Wrap>
            <Wrap>
              <img src="../images/designtools.svg" alt="not-found" />
              <div>
                <p>{t("career.heroSection.card.title3")}</p>
                <span>{t("career.heroSection.card.desc3")}</span>
              </div>
            </Wrap>
            <Wrap>
              <img src="../images/money-2.svg" alt="not-found" />
              <div>
                <p>{t("career.heroSection.card.title4")}</p>
                <span>{t("career.heroSection.card.desc4")}</span>
              </div>
            </Wrap>
          </CareerCardWrapper>
        </CustomiseCard>
      </BoxFrame>
    </Wrapper>
  );
};

export default CareerHeroSection;
