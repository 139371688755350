import styled from "styled-components";

export const MarkdownWrapper = styled.div`
    line-height: normal;
    h1,h2,h3,h4{
    }
    p{
        margin: 5px 0px;
    }
    img{
        max-width:100%;
        height:auto;
        margin: 0px auto;
    }
`