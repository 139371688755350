import { FormControl } from "@mui/material";
import styled from "styled-components";

export const CustomiseFormControl = styled(FormControl)`
  width: 100%;
  border: none;
  div {
    border-color: #e5e5e5;
    border-radius: 12px;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #666666;
    outline: none;

    fieldset {
      border-width: 1px !important;
      border-color: #e5e5e5 !important;
    }
  }
`;
