import React from "react";
import { CustomiseTextarea } from "./style";

const Textarea = ({ minRows, placeholder }) => {
  return (
    <>
      <CustomiseTextarea minRows={minRows} placeholder={placeholder} />
    </>
  );
};

export default Textarea;
