import styled from "styled-components";

export const Wrapper = styled.section`
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: auto;
  min-height: fit-content;
  padding: 2em;
  border-radius: 8px;
  background: lightgray;
  z-index: 1;
`;

export const PopUpBody = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  a {
    margin-left: 4px;
    color: rgb(255, 147, 30);
  }
`;