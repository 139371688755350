import React, { useState } from "react";
import { MenuItem } from "../Navbar/style";
import { DropdownItem } from "../PoperDropdown/style";

const DropDown = ({ page, onClose }) => {
  const [options, setOptions] = useState(null);
  const clickHandler = () => {
    setOptions((prevOption) => {
      return prevOption?.length ? [] : page.options;
    });
  };
  return (
    <>
      <MenuItem onClick={clickHandler} primary={options?.length ? true : false}>
        {page.name}
        {<img id={"menu-img"} className="menu-img" src="/images/Path 94.svg" alt="not-found" />}
        {options?.map((option) => {
          return (
            <MenuItem onClick={onClose} to={option.navigate}>
              <DropdownItem>
                {option.imageUrl && <img className="menu-img" src={option.imageUrl} alt="" />}
                <span>
                  <p>{option.name}</p>
                </span>
              </DropdownItem>
            </MenuItem>
          );
        })}
      </MenuItem>
    </>
  );
};

export default DropDown;
