import * as React from "react";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, PaginationItem } from "@mui/material";
import { ParaTitle, CardPara, Date } from "../style";
import {
  CenterHeading,
  FlexDiv,
  HeadingSecondary,
  PaginationWrapper,
  NewsWrapper,
  NewsCard,
  NewsLink,
  CardMediaWrapper,
  CardMediaInner,
} from "./style";
import { useTranslation } from "react-i18next";
import { CustomPagination } from "../JobOpportunity/style";
import { FirstPageIcon } from "../../Icons/FirstPageIcon";
import { LastPageIcon } from "../../Icons/LastPageIcon";
import { Link } from "react-router-dom";

export const NewsMain = () => {
  const [blogitems, setBlogitems] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(1);
  React.useEffect(() => {
    pageChange(null, 1);
  }, []);

  const { t } = useTranslation();
  const pageChange = (event, page) => {
    fetch(
      `${process.env.REACT_APP_CMSURL}/api/blogs?populate=*&pagination[page]=${page}&pagination[pageSize]=9&sort[0]=Date:desc`
    )
      .then((response) => response.json())
      .then((response) => {
        setPageCount(response?.meta?.pagination.pageCount);
        setBlogitems(response?.data ?? []);
      });
  };
  return (
    <NewsWrapper>
      <CenterHeading>{t("news.news")}</CenterHeading>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {blogitems.map((item, key) => (
          <>
            <Grid item md={4} sm={6} xs={12}>
              <NewsLink to={`/news/${item.documentId}`}>
                <NewsCard>
                  <CardActionArea>
                    <CardMediaWrapper>
                      <CardMediaInner>
                        <CardMedia
                          component="img"
                          image={`${process.env.REACT_APP_CMSURL}${
                            item.Image?.url ?? ""
                          }`}
                          alt="green iguana"
                        />
                      </CardMediaInner>
                    </CardMediaWrapper>
                    <CardContent sx={{ display: "grid", gap: "12px" }}>
                      <Date>{item.Date}</Date>
                      <ParaTitle>{item.Title}</ParaTitle>
                      <CardPara>{item.Brief}</CardPara>
                      <FlexDiv>
                        <img
                          src={`${process.env.REACT_APP_CMSURL}${
                            item.AuthorImage?.url ?? ""
                          }`}
                          alt="business"
                        />
                        <div>
                          <CardPara active="true">
                            {t("news.publishedBy")}
                          </CardPara>
                          <HeadingSecondary>{item.Author}</HeadingSecondary>
                        </div>
                      </FlexDiv>
                    </CardContent>
                  </CardActionArea>
                </NewsCard>
              </NewsLink>
            </Grid>
          </>
        ))}
      </Grid>
      <PaginationWrapper>
        <CustomPagination
          onChange={pageChange}
          count={pageCount}
          variant="outlined"
          shape="rounded"
          showFirstButton
          showLastButton
          renderItem={(item) => (
            <PaginationItem
              components={{
                first: FirstPageIcon,
                last: LastPageIcon,
              }}
              {...item}
            />
          )}
        />
      </PaginationWrapper>
    </NewsWrapper>
  );
};
