import {
    ContentWrapper
} from "../../../../Pages/style"
import {
    GridWrapper
} from "./styles"
import GridCard from "./GridCard"
import { Grid } from "@mui/material";

const GridContainer = ({attr}) => {
    return (
        <ContentWrapper>
            <GridWrapper>
                <Grid container spacing={2}>
                {attr.Cards.map((Card)=>{
                    return <GridCard attr={Card} />
                })}
                </Grid>
            </GridWrapper>
        </ContentWrapper>
    )
}

export default GridContainer