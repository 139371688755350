import React from "react";
import BoxFrame from "../BoxFrame";
import {FrameTitle, FrameContent, FrameCard, Para} from "../style";
import { Wrapper } from "./style";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "@mui/material";

const PricingBlocks = () => {
  const { t } = useTranslation();
  const handleClick = (event) => {
    event.preventDefault()
    const mailtoURL = `mailto: info@digidoe.com`;
    window.open(mailtoURL);
  }
  return (
    <Wrapper>
      <BoxFrame alignImage="right" dataImage="/images/WoodenHands.svg">
        <FrameTitle>{t("pricing.title")}</FrameTitle>
        <FrameContent>
          <div className={'theses'}>
            <p>{t("pricing.desc1")}</p>
            <p>{t("pricing.desc2")}</p>
            <p>{t("pricing.desc3")}</p>
          </div>
          <FrameCard>
            <img src="/images/01.svg" alt="not-found" />
            <div className="content">
              <h3>{t("pricing.card.desc1.title")}</h3>
              <h4>{t("pricing.card.desc1.subtitle")}</h4>
              <p>{t("pricing.card.desc1.content")}</p>
              <Link href={"/pdfs/Pricing_Structure_for_Retail_Clients.pdf"} target={"_blank"} className={"mailLink"}>
                <span>Retail customers prices</span>
              </Link>
            </div>
          </FrameCard>
          <FrameCard>
            <img src="/images/02.svg" alt="not-found" />
            <div className="content">
              <h3>{t("pricing.card.desc2.title")}</h3>
              <h4>{t("pricing.card.desc2.subtitle")}</h4>
              <p>{t("pricing.card.desc2.content")}</p>
              <Link onClick={handleClick} className={"mailLink"} to={'#'}>
                <span>Contact for details</span>
              </Link>
            </div>
          </FrameCard>
          <FrameCard>
            <img src="/images/03.svg" alt="not-found" />
            <div className="content">
              <h3>{t("pricing.card.desc3.title")}</h3>
              <h4>{t("pricing.card.desc3.subtitle")}</h4>
              <p>{t("pricing.card.desc3.content")}</p>
              <Link onClick={handleClick} className={"mailLink"} to={"#"}>
                <span>Contact for details</span>
              </Link>
            </div>
          </FrameCard>
        </FrameContent>
      </BoxFrame>
    </Wrapper>
  );
};

export default PricingBlocks;
