import { Breadcrumbs, Link } from "@mui/material";
import ContactUs from "../Components/ContactUs";
import { Breadcrumb } from "../Components/style";
import { MainContainer } from "./style";
import { useTranslation } from "react-i18next";

const ContactUsPage = () => {
  const { t } = useTranslation();
  return (
    <MainContainer spacing={true} isWhiteBg={true}>
      <Breadcrumb>
        <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            <img src="/images/home2.svg" alt="" />
            {t("breadCrumb.main")}
          </Link>
          <Link underline="hover" color="inherit" href="/contactUs" className="active">
            {t("breadCrumb.contactUs")}
          </Link>
        </Breadcrumbs>
      </Breadcrumb>
      <ContactUs dataImage="/images/Frame 48095694.svg" />
    </MainContainer>
  );
};

export default ContactUsPage;
