import React from "react";
import { useParams } from "react-router-dom";
import { BtnOrange, CustomiseCard, Heading } from "../style";
import { useTranslation } from "react-i18next";
import {
  CustomUL,
  JobDescription,
  JobDescriptionPoint,
  JobDescriptionTitle,
  JobInfoWrapper,
  JobPost,
  JobPostWrap,
  JobSocialButton,
  JobSocialMedia,
  JobTitle,
} from "../JobsList/style";
import { Link } from "react-router-dom";
import Markdown from "../Strapi/Markdown";

const JobPostDetail = () => {
  const [item, setCareerItems] = React.useState([]);
  const { careerId } = useParams();
  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_CMSURL}/api/job-opportunities/${careerId}?populate=*`
    )
      .then((response) => response.json())
      .then((data) => setCareerItems(data.data));
  }, []);
  const copy = () => {
    navigator.clipboard.writeText(window.location.href);
  };
  const { t } = useTranslation();
  return (
    <>
      <CustomiseCard padding="36px">
        <JobInfoWrapper>
          <JobTitle>
            <Heading>{item.Title}</Heading>
          </JobTitle>
          <JobSocialMedia>
            <JobSocialButton onClick={copy} sx={{ mr: "10px" }}>
              <img
                src="/images/TypoLiner.svg"
                alt="not-found"
                style={{ marginRight: "10px" }}
              />
              {t("careerDetails.jobPostDetails.postDetail.socialBtn1")}
            </JobSocialButton>
            <Link to="https://forms.office.com/e/fT3EB77Tae">
              <BtnOrange>
                {t("careerDetails.jobPostDetails.postDetail.socialBtn3")}
              </BtnOrange>
            </Link>
          </JobSocialMedia>
        </JobInfoWrapper>
        <JobPost margin="24px 0">
          <JobPostWrap>
            <img src="/images/LocationLiner.svg" alt="not-found" />
            <span>{item?.Location?.Location}</span>
          </JobPostWrap>
          <JobPostWrap>
            <img src="/images/TimeLiner.svg" alt="not-found" />
            <span>{item.Date}</span>
          </JobPostWrap>
          <JobPostWrap>
            <img src="/images/ContentLiner.svg" alt="not-found" />
            <span>{item.JobCategory}</span>
          </JobPostWrap>
        </JobPost>
        <JobDescriptionPoint>
          <Markdown content={item.Content} />
        </JobDescriptionPoint>
        <Link to="https://forms.office.com/e/fT3EB77Tae">
          <BtnOrange>
            {t("careerDetails.jobPostDetails.postDetail.socialBtn3")}
          </BtnOrange>
        </Link>
      </CustomiseCard>
    </>
  );
};

export default JobPostDetail;
