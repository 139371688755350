import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import BoxFrame from "../BoxFrame";
import { CustomiseCard, FrameContent, FrameContentTitle, FrameTitle } from "../style";
import { Caption, Wrapper } from "./style";

const MonitoringSystem = () => {
  const { t } = useTranslation();

  const caption = (
    <Caption>
      <FrameContent>
        <FrameContentTitle>
          <p>{t("monitoringSystem.caption.title")}</p>
        </FrameContentTitle>
        <p>{t("monitoringSystem.caption.description")}</p>
      </FrameContent>
    </Caption>
  );

  return (
    <Wrapper>
      <BoxFrame alignImage="right" dataImage="/images/Frame48095720.svg" caption={caption}>
        <FrameTitle>{t("monitoringSystem.title")}</FrameTitle>
        <FrameContent>
          <FrameContentTitle>
            <p>{t("monitoringSystem.desc1Title")}</p>
          </FrameContentTitle>
          <p>{t("monitoringSystem.desc1")}</p>

          <Grid container columns={{ xs: 1, sm: 6, md: 12 }} spacing={2}>
            <Grid item xs={1} sm={6} md={6}>
              <CustomiseCard background="rgba(162, 162, 163, 0.08)" padding="16px" margin="8px 0">
                <img src="/images/01.svg" alt="not-found" />
                <p>{t("monitoringSystem.card.desc1")}</p>
              </CustomiseCard>
            </Grid>
            <Grid item xs={1} sm={6} md={6}>
              <CustomiseCard background="rgba(162, 162, 163, 0.08)" padding="16px" margin="8px 0">
                <img src="/images/02.svg" alt="not-found" />
                <p>{t("monitoringSystem.card.desc2")}</p>
              </CustomiseCard>
            </Grid>
            <Grid item xs={1} sm={6} md={6}>
              <CustomiseCard background="rgba(162, 162, 163, 0.08)" padding="16px" margin="8px 0">
                <img src="/images/03.svg" alt="not-found" />
                <p>{t("monitoringSystem.card.desc3")}</p>
              </CustomiseCard>
            </Grid>
            <Grid item xs={1} sm={6} md={6}>
              <CustomiseCard background="rgba(162, 162, 163, 0.08)" padding="16px" margin="8px 0">
                <img src="/images/04.svg" alt="not-found" />
                <p>{t("monitoringSystem.card.desc4")}</p>
              </CustomiseCard>
            </Grid>
            <Grid item xs={1} sm={6} md={6}>
              <CustomiseCard background="rgba(162, 162, 163, 0.08)" padding="16px" margin="8px 0">
                <img src="/images/05.svg" alt="not-found" />
                <p>{t("monitoringSystem.card.desc5")}</p>
              </CustomiseCard>
            </Grid>
            <Grid item xs={1} sm={6} md={6}>
              <CustomiseCard background="rgba(162, 162, 163, 0.08)" padding="16px" margin="8px 0">
                <img src="/images/06.svg" alt="not-found" />
                <p>{t("monitoringSystem.card.desc6")}</p>
              </CustomiseCard>
            </Grid>
            <Grid item xs={1} sm={6} md={6}>
              <CustomiseCard background="rgba(162, 162, 163, 0.08)" padding="24px" margin="24px 0">
                <img src="/images/07.svg" alt="not-found" />
                <p>{t("monitoringSystem.card.desc7")}</p>
              </CustomiseCard>
            </Grid>
            <Grid item xs={1} sm={6} md={6}>
              <CustomiseCard background="rgba(162, 162, 163, 0.08)" padding="24px" margin="24px 0">
                <img src="/images/08.svg" alt="not-found" />
                <p>{t("monitoringSystem.card.desc8")}</p>
              </CustomiseCard>
            </Grid>
          </Grid>
        </FrameContent>
      </BoxFrame>
    </Wrapper>
  );
};

export default MonitoringSystem;
