import React from "react";
import { CardWrapper } from "./style";

const CardWithImage = ({ cardTitle, cardTitleHighlight, imgPath }) => {
  return (
    <CardWrapper>
      <p className="card-title">
        {cardTitle} 
      </p>
      <img src={imgPath} alt="not found" />
    </CardWrapper>
  );
};

export default CardWithImage;
