import { Link, Tab, Tabs } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 5.208333333333333vw;
  padding-bottom: 100px;
`;

export const JobPostFilterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 25px 25px 0 25px;

  @media screen and (max-width: 899px) {
    padding: 0;
  }

  @media screen and (max-width: 374px) {
    flex-wrap: wrap;
  }
`;

export const JobPostLink = styled(Link)`
  font-family: "SF Pro Text" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: #121212 !important;
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 899px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const JobPostTabs = styled(Tabs)`
  .Mui-selected {
    background: rgba(162, 162, 163, 0.08) !important;
  }
`;
export const JobItem = styled(Link)`
  color: rgb(143, 154, 168) !important;
  button {
    opacity: 1 !important;
  }
`;
export const JobPostTab = styled(Tab)`
  background: #ffffff;
  font-family: "SF Pro Text" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: #4d4d4d;
  align-items: flex-start !important;
  text-align: start !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: unset !important;
  min-width: unset !important;
  min-height: unset !important;
  letter-spacing: unset !important;
  text-transform: capitalize !important;

  @media screen and (max-width: 899px) {
    font-size: 16px !important;
    line-height: 19px !important;
  }
`;

export const JobPostTabLabelWrap = styled.div`
  margin: 26px;
`;
