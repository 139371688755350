import React from 'react';
import {VideoContainer} from "./style";

const YouTubeVideo = ({ videoId }) => {
  return (
    <VideoContainer>
      <iframe
        className="video-player"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </VideoContainer>
  );
};

export default YouTubeVideo;