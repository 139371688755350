import React from "react";
import { useTranslation } from "react-i18next";
import BoxFrame from "../BoxFrame";
import { FrameContent, FrameContentTitle, FrameTitle } from "../style";
import { Wrapper } from "./style";

const Matching = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BoxFrame alignImage="right" dataImage="/images/Frame48095717.svg">
        <FrameTitle>{t("matching.title")}</FrameTitle>
        <FrameContent>
          <FrameContentTitle>
            <p>{t("matching.desc1Title")}</p>
          </FrameContentTitle>
          <p>{t("matching.desc1")}</p>
          <FrameContentTitle>
            <p>{t("matching.desc2Title")}</p>
          </FrameContentTitle>
          <p>{t("matching.desc2")}</p>
        </FrameContent>
      </BoxFrame>
    </Wrapper>
  );
};

export default Matching;
